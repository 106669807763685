import React from 'react'
import {Redirect,} from "react-router-dom";
import {getRights, isLoggedIn} from "../functions/Auth";
import {Col, Container, Row, Spinner, Button} from "reactstrap";

import NavSidebar from "./components/NavSidebar";
import SearchBar from "./components/SearchBar";
import AppPage from "../AppPage";
import {
    filterDeliveryRecords,
    getAllDeliveryRecords,
    getAllDrivers,
    getDeliveryRecordsFast,
    sortDeliveryRecords
} from "../functions/Delivery";
import DeliveryRecordCard from "./DeliveryRecordCard";
import SearchDateRangeBar from "./components/SearchDateRangeBar";
import SearchSortingBar from "./components/SearchSortingBar";
import SpinnerOverlay from "./components/SpinnerOverlay";
import DeliveryRecordDetailModal from "./DeliveryRecordDetailModal";
import DeliveryRecordStatusFilter from "./DeliveryRecordStatusFilter";
import ScreenPullToRefresh from "./components/ScreenPullToRefresh";
import {getAllCars} from "../functions/Car";
import BillRecordStatusFilter from "./BillRecordStatusFilter";



function DeliveryRecordScreen() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    //  Initial loading
    const [initial, setInitial] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    //  Data
    const [deliveryData, setDeliveryData] = React.useState([]);
    const [fastDataOnly, setFastDataOnly] = React.useState(false);  //  Not loading summary if only fast data is loaded
    const [displayData, setDisplayData] = React.useState([]);
    //  Search and filter
    const [searchString, setSearchString] = React.useState('');
    const [searchStartDate, setSearchStartDate] = React.useState(today);
    const [searchEndDate, setSearchEndDate] = React.useState(today);
    const [deliveryStatusList, setDeliveryStatusList] = React.useState({scheduled: true, prepared: true, onTheWay: true, complete: true});
    const [billStatusList, setBillStatusList] = React.useState({notPaid: true, paid: true});
    //  Sorting
    const [sortColumnName, setSortColumnName] = React.useState('proposedDateTime');
    const [sortOrder, setSortOrder] = React.useState(-1);   //  Descending
    //  Item onClick
    const [itemIDClicked, setItemIDClicked] = React.useState('');

    //  Sorting
    const columnsToSort = [
        {
            columnName: "proposedDateTime",
            name: "日期"
        },
        {
            columnName: "netPrice",
            name: "價錢"
        }
    ];
    async function onModalToggle() {
        let deliveryData;
        setItemIDClicked('');
        deliveryData = await getAllDeliveryRecords();
        setDeliveryData(deliveryData);
        setIsLoading(false);
    }
    //  Pull down to refresh
    async function onPullDownRefresh() {
        let deliveryData;
        deliveryData = await getAllDeliveryRecords();
        console.log(deliveryData);
        setDeliveryData(deliveryData);
    }

    //  Run when state value changes
    React.useEffect(() => {
        //  Initial API call
        const initData = async () => {
            //  Load once only
            setInitial(true);
            //  Get 100 records first
            let deliveryData;
            deliveryData = await getAllDeliveryRecords();
         //   deliveryData = await getDeliveryRecordsFast();
            setDeliveryData(deliveryData);
            setFastDataOnly(true);
            await getAllDrivers();
            await getAllCars();
            setIsLoading(false);
            //  Full record list
           // deliveryData = await getAllDeliveryRecords();
            setDeliveryData(deliveryData);
            setFastDataOnly(false);
        };
        if (!initial) {
            initData().then();
        }
        //  Filter and sort
        let filteredData, sortedData;
        filteredData = filterDeliveryRecords(deliveryData, searchString, searchStartDate, searchEndDate, deliveryStatusList, billStatusList);
        sortedData = sortDeliveryRecords(filteredData, sortColumnName, sortOrder);
        setDisplayData(sortedData);        
    }, [initial, deliveryData, searchString, searchStartDate, searchEndDate, deliveryStatusList, sortColumnName, sortOrder, billStatusList]);

    return isLoggedIn() ? (
        <Container className="pl-3 pr-3">
            {isLoading ? <SpinnerOverlay/> : ""}
            <NavSidebar/>
            <ScreenPullToRefresh onRefresh={async () => await onPullDownRefresh()}
                                 disabled={itemIDClicked !== ''}>
                <SearchBar searchValue={searchString}
                           onSearchChange={(search) => setSearchString(search)}
                           placeholder="搜尋（POS Number／司機／車牌／地址／客戶名／備註）"/>
                <SearchDateRangeBar onStartDateChange={(date) => {setSearchStartDate(date)} }
                                    onEndDateChange={(date) => setSearchEndDate(date)}
                                    defaultStartDate={searchStartDate}
                                    defaultEndDate={searchEndDate} />
                <SearchSortingBar columnsToSort={columnsToSort}
                                  defaultColumn={columnsToSort[0]}
                                  onSortChange={(columnName, order) => {
                                      setSortColumnName(columnName);
                                      setSortOrder(order);
                                  }} />
                <DeliveryRecordStatusFilter onFilterChange={(statusList) => setDeliveryStatusList(statusList)}/>

                {getRights() !== "driver" ? (
                    <BillRecordStatusFilter onFilterChange={(statusList) => setBillStatusList(statusList)}/>
                ) :""}

                <Row className="bg-secondary mt-1 p-3">
                    <Col xs={12} className="mb-2">
                        <strong className={"bg-primary p-1 text-light"}>總數</strong>{' '}
                        {fastDataOnly ? <Spinner size="sm" color="primary" /> : displayData.length}
                    </Col>
                    <Col xs={3}>
                        <strong className={"bg-info p-1 text-dark"}>排程中</strong>{' '}
                        {fastDataOnly ? <Spinner size="sm" color="info" /> : displayData.filter(delivery => delivery.deliveryStatus === "排程中").length}
                    </Col>
                    <Col xs={3}>
                        <strong className={"bg-danger p-1 text-dark"}>準備好</strong>{' '}
                        {fastDataOnly ? <Spinner size="sm" color="primary" /> : displayData.filter(delivery => delivery.deliveryStatus === "準備好").length}
                    </Col>
                    <Col xs={3}>
                        <strong className={"bg-warning p-1 text-dark"}>送緊貨</strong>{' '}
                        {fastDataOnly ? <Spinner size="sm" color="warning" /> : displayData.filter(delivery => delivery.deliveryStatus === "送緊貨").length}
                    </Col>
                    <Col xs={3}>
                        <strong className={"bg-success p-1 text-dark"}>完成</strong>{' '}
                        {fastDataOnly ? <Spinner size="sm" color="success" /> : displayData.filter(delivery => delivery.deliveryStatus === "完成").length}
                    </Col>
                </Row>
                <Row>
                    {displayData.map((delivery, index) => (
                        <Col xs={12} sm={12} md={6} lg={4}
                             data-id={delivery.deliveryID}
                             key={delivery.deliveryID}
                             onClick={() => setItemIDClicked(delivery.deliveryID)}>
                            <DeliveryRecordCard data={delivery}/>
                        </Col>
                    ))}
                </Row>
            </ScreenPullToRefresh>
            <DeliveryRecordDetailModal isOpen={itemIDClicked !== ''}
                                       toggle={onModalToggle}
                                       data={deliveryData.find(delivery => delivery.deliveryID === itemIDClicked)}/>
            
        </Container>
    ) : (
        <Redirect to={AppPage.login.route} />
    )
}

export default DeliveryRecordScreen
