import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { updateGoodsRecords } from "../functions/Goods";
import { sleep } from "../functions/Utility";
import { Button, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from "reactstrap";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";

const useStyles = makeStyles({
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    cover: {
        minWidth: 120,
        flex: '0.4 0 auto',
    }
});

function BonusStatCard(props) {
    const classes = useStyles();
    const { data, onDetailClick, onUpdateStart, onUpdateEnd } = props;

    //  Inventory edit
    const inventoryRef = useRef(null);
    const [inventory, setInventory] = React.useState(data.inventory);
    const [inventoryIsError, setInventoryIsError] = React.useState(false);
    //  General data update function
    const onDataUpdate = async (updateData) => {
        onUpdateStart();
        await updateGoodsRecords(data.goodID, updateData);
        await sleep(500);
        Object.assign(data, updateData);
        onUpdateEnd();
    };

    return (
        <div className="mt-3">
            <Card className={classes.card}>
                <CardActionArea onClick={onDetailClick}>
                    <Container>
                        <Row>
                            <CardContent className="col-6">
                                <Typography variant="h5" component="h2">
                                    {data.schemeName}
                                </Typography>
                                <Typography className={classes.pos} color="textSecondary">
                                    {data.schemeType} | {data.schemeRemark}
                                </Typography>
                                <Typography className={classes.pos} color="textSecondary">
                                    {data.salesVolume}
                                </Typography>
                            </CardContent>
                            <CardContent className="col-6">
                                <Typography variant="h5" component="h2">
                                    ${data.BonusSchemeDetails[0]?.schemeBonus}
                                </Typography>
                                <Typography variant="h10" component="h5">
                                    Vol: ${data.BonusSchemeDetails[0]?.salesVolume}
                                </Typography>
                                <Typography variant="h10" component="h5">
                                    Rate: {data.BonusSchemeDetails[0]?.schemeRate}
                                </Typography>
                                <Typography className={classes.pos} color="textSecondary">
                                {data.BonusSchemeDetails[0]?.schemeDetailName}
                                </Typography>
                            </CardContent>
                        </Row>
                    </Container>
                </CardActionArea>

            </Card>
        </div>
    );
}

export default BonusStatCard
