import React, {useRef} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader, Button, Input} from "reactstrap";
import useWindowDimensions from "../functions/Utility";



function DeliveryRecordUpdateDeliveryRemarkModal(props) {
    const {isOpen, toggle, onSubmit, originalDeliveryRemark} = props;
    const [originalDeliveryRemarkToUse, setOriginalDeliveryRemarkToUse] = React.useState('');
   // const inputEl = useRef(null);

   React.useEffect(() => {
    setOriginalDeliveryRemarkToUse(originalDeliveryRemark);
   }, [originalDeliveryRemark])


    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                 更改收貨資料
            </ModalHeader>
            <ModalBody>
            <Input 
                className="mr-2 d-inline"
                type="textarea" 
                value={originalDeliveryRemarkToUse}
                style={{height: '100px'}} 
                onChange={e => setOriginalDeliveryRemarkToUse(e.target.value)}
                >
                </Input>
                <Button  color="danger"className="mr-2 d-inline" onClick={() => setOriginalDeliveryRemarkToUse('')}>清空</Button>  
            </ModalBody>
            <ModalFooter>
                <Button color="info" className="mr-2 d-inline" onClick={() => onSubmit((originalDeliveryRemarkToUse === "" ? "" : '#' + originalDeliveryRemarkToUse.replace(/#/g, '') + '#'))} >確定</Button>         
                <Button  color="danger"className="mr-2 d-inline" onClick={toggle}>取消</Button>
            </ModalFooter>
        </Modal>
    );
}

export default DeliveryRecordUpdateDeliveryRemarkModal
