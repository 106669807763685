import React, {useState} from "react";

import './NavSidebar.default.css';
import './NavSidebar.css';
import {
    Button, Container, DropdownItem, DropdownMenu, DropdownToggle, InputGroup, InputGroupAddon, InputGroupButtonDropdown, InputGroupText, Row
} from "reactstrap";
import '@fortawesome/fontawesome-free/css/all.min.css';
import "react-datepicker/dist/react-datepicker.css";

import "./SearchSortingBar.css";


function SearchSortingBar(props) {
    const {defaultColumn, columnsToSort, onSortChange} = props;

    const [sortDropdownOpen, setSortDropdownOpen] = useState(false);
    const [sortColumn, setSortColumn] = useState(defaultColumn ?? null);
    const [sortOrder, setSortOrder] = useState(-1);

    function toggleDropDown() {
        setSortDropdownOpen(!sortDropdownOpen)
    }

    function onDropdownClick(column) {
        setSortColumn(column);
        onSortChange(column.columnName, sortOrder);
    }

    function toggleSortOrder() {
        let sortColumnName, newSortOrder;
        if (sortColumn === null) {
            sortColumnName = '';
        } else {
            sortColumnName = sortColumn.columnName;
        }
        if (sortOrder === 0) {
            newSortOrder = 1;
        } else {
            newSortOrder = -sortOrder;
        }
        setSortOrder(newSortOrder);
        onSortChange(sortColumnName, newSortOrder);
    }

    return (
        <Container className="search-sorting-bar">
            <Row className="mt-2">
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText><i className="fas fa-sort"/></InputGroupText>
                        <InputGroupButtonDropdown addonType="append" isOpen={sortDropdownOpen} toggle={toggleDropDown}>
                            <DropdownToggle caret>
                                {sortColumn === null ? '方式' : sortColumn.name}
                            </DropdownToggle>
                            <DropdownMenu>
                                {columnsToSort.map((column, index) => {
                                    return (
                                        <DropdownItem onClick={() => onDropdownClick(column)}
                                                      key={column.columnName}>
                                            {column.name}
                                        </DropdownItem>
                                    )
                                })}
                            </DropdownMenu>
                        </InputGroupButtonDropdown>
                        {(sortColumn !== null) ?
                        <Button onClick={toggleSortOrder}>
                            {(sortOrder === 1) ? (
                                <>
                                    <i className="fas fa-sort-numeric-up"/>小至大
                                </>
                            ) : (
                                <>
                                    <i className="fas fa-sort-numeric-down-alt"/>大至小
                                </>
                            )}
                        </Button> : <span/>
                        }
                    </InputGroupAddon>
                </InputGroup>
            </Row>
        </Container>
    );
}

export default SearchSortingBar