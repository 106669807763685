import React, { useState } from 'react'
import './wsp-gallery.css'

import { Button, ButtonGroup } from 'reactstrap'
import { getImageType,sleep } from "../../functions/Utility.js";

import { setInUse, setNotInUse, deleteInvoicePromoPicture } from "../../functions/Invoice80mmPicture";
import { Label } from 'reactstrap';

const WSPGallery = ({ URLBase64s, setIsLoading }) => {

    const [slideNumber, setSlideNumber] = useState(0);
    const [openModal, setOpenModal] = useState(false);

    //hover 
    const [isHovered, setIsHovered] = useState(false);
    const [photoArray, setPhotoArray] = useState([]);


    const setUseOrNotInDb = async (ImageName, currentInUseOrNot) => {
        if (currentInUseOrNot === 'inUse') {
            console.log(await setNotInUse(ImageName));
        }
        if (currentInUseOrNot === 'notInUse') {
            console.log(await setInUse(ImageName));
        }
    }

    const deletePhotoInArray = async (ImageNameToDelete) => {
        setIsLoading(true);
        await sleep(1000);
        let testItem = photoArray.filter((item, index, arr) => {
            return item.ImageName !== ImageNameToDelete;
        });
        setPhotoArray(testItem);
        setIsLoading(false);
    }

    const setUseOrNotInArray = async (ImageName, currentInUseOrNot) => {
        if (currentInUseOrNot === 'inUse') {
            setIsLoading(true);
            await sleep(1000);
            let tempArray = photoArray;
            const index = tempArray.findIndex(object => {
                return object.ImageName === ImageName;
            });

            if (index !== -1) {
                tempArray[index].ImageType = 'notInUse';
            }
            setPhotoArray(tempArray);
            setIsLoading(false);
        }
        if (currentInUseOrNot === 'notInUse') {
            setIsLoading(true);
            await sleep(1000);
            const index = photoArray.findIndex(object => {
                return object.ImageName === ImageName;
            });

            if (index !== -1) {
                photoArray[index].ImageType = 'inUse';
            }
          //  let testItem = photoArray.pop();
          // photoArray.push(testItem);
            setPhotoArray(photoArray);
            setIsLoading(false);
        }
    }


    React.useEffect(() => {
        setPhotoArray(URLBase64s);
    }, [URLBase64s]);

    return (
        <div>
            <div className='galleryWrap'>
                {
                    photoArray.map((slide, index) => {
                        return (
                            <div className={'single' + slide.ImageType}
                                key={index}
                                onMouseOver={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}>
                                <img src={`data:image/${getImageType(slide.ImageBase64Content).ext};base64,${slide.ImageBase64Content}`} alt='' style={{ width: '100%' }} />
                                {isHovered && (slide.ImageType === "inUse") && (
                                    <ButtonGroup className="mb-2" style={{
                                        position: "absolute",
                                        bottom: "10px",
                                        right: "10px",
                                    }}>
                                        <Button size="lg" color="warning" variant="primary"
                                            onClick={async () => {
                                                await setUseOrNotInDb(slide.ImageName, slide.ImageType);
                                                await setUseOrNotInArray(slide.ImageName, slide.ImageType);

                                            }}
                                        >
                                            <i className="fas fa-ban" />
                                        </Button>
                                        <Button color="danger" size="lg" variant="primary"
                                            onClick={async () => {
                                                await deleteInvoicePromoPicture(slide.ImageName);
                                                await   deletePhotoInArray(slide.ImageName);

                                            }}
                                        > <i className="fas fa-trash" />
                                        </Button>
                                    </ButtonGroup>
                                )}

                                {isHovered && (slide.ImageType === "notInUse") && (

                                    <ButtonGroup className="mb-2" style={{
                                        position: "absolute",
                                        bottom: "10px",
                                        right: "10px",
                                    }}>
                                        <Button color="success" size="lg" variant="primary"
                                            onClick={async () => {
                                                await setUseOrNotInDb(slide.ImageName, slide.ImageType);
                                                await setUseOrNotInArray(slide.ImageName, slide.ImageType);
                                            }}
                                        ><i className="fas fa-check" />
                                        </Button>
                                        <Button color="danger" size="lg" variant="primary"
                                            onClick={async () => {
                                                await deleteInvoicePromoPicture(slide.ImageName);
                                                await  deletePhotoInArray(slide.ImageName);
                                            }}
                                        ><i className="fas fa-trash" />
                                        </Button>
                                    </ButtonGroup>

                                )}
                            </div>
                        )
                    })
                }
            </div>






        </div>
    )
}

export default WSPGallery