import React from 'react';
import {isAndroid, isChrome, isEdge, isFirefox, isIOS, isSafari} from "react-device-detect";
import * as Sentry from "@sentry/react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Toast, ToastBody, ToastHeader} from "reactstrap";
import Geocode from "react-geocode";
import {GoogleMap, LoadScript} from '@react-google-maps/api';
import SpinnerOverlay from "./components/SpinnerOverlay";
import {goToUri, isEmpty} from "../functions/Utility";


function DeliveryRecordGoogleMapModal(props) {
    const {isOpen, toggle, address, initLatitude, initLongitude, onLocationSelected} = props;

    //  Initial loading
    const [initialized, setInitialized] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [toastMessage, setToastMessage] = React.useState(null);
    //  Location
    const [latitude, setLatitude] = React.useState(initLatitude);
    const [longitude, setLongitude] = React.useState(initLongitude);
    const [currentPosition, setCurrentPosition] = React.useState({});
    const [map, setMap] = React.useState(null);

    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);
    Geocode.setLanguage("zh-HK");
    Geocode.setRegion("HK");

    const onLoad = React.useCallback(function callback(map) {
        setMap(map);
    }, []);

    const onCenterChanged = () => {
        if (map) {
            setLatitude(map.center.lat());
            setLongitude(map.center.lng());
        }
    };

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);
    
    const getSettingsGuide = () => {
        let browserName;
        if (isIOS) {
            if (isSafari) {
                browserName = 'Safari';
            } else if (isChrome) {
                browserName = 'Chrome';
            } else {
                browserName = '瀏覧器名稱'
            }
            return `更改設定：(設定 ＞ 私隱 ＞ 定位服務 ＞ ${browserName} ＞ 詢問)；以及 更改瀏覧器設定：(設定 ＞ ${browserName} ＞ 位置 ＞ 詢問)`;
        } else if (isAndroid) {
            if (isChrome) {
                browserName = 'Chrome';
            } else if (isFirefox) {
                browserName = 'Firefox';
            } else if (isEdge) {
                browserName = 'Edge'
            } else {
                browserName = '瀏覧器名稱'
            }
            return `更改手機設定：(設定 ＞ 位置 ＞ ${browserName} ＞ 權限 ＞ 允許)；以及 更改瀏覧器設定`
        } else {
            return '';
        }
    };
    const updateCurrentPosition = (updateMap) => {
        if (navigator.geolocation) {
            let options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            };
            setIsLoading(true);
            navigator.geolocation.getCurrentPosition((position) => {
                setCurrentPosition(position.coords);
                if (updateMap) {
                    setLatitude(position.coords.latitude);
                    setLongitude(position.coords.longitude);
                }
                setIsLoading(false);
            }, (error) => {
                let errorMessage;
                setIsLoading(false);
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        errorMessage = (
                            <div>
                                <div>請允許位置權限</div>
                                <div>{getSettingsGuide()}</div>
                            </div>
                        );
                        break;
                    case error.POSITION_UNAVAILABLE:
                        errorMessage = "取不到目前定位";
                        break;
                    case error.TIMEOUT:
                        errorMessage = "請求時間太長，請重新嘗試";
                        break;
                    default:
                        errorMessage = "未知錯誤";
                }
                setToastMessage(errorMessage);
                Sentry.captureException(new Error(error.message));
            }, options);
        } else {
            setToastMessage("您的裝置不支援GPS定位");
        }
    };

    //  Run when state value changes
    React.useEffect(() => {
        const initData = async () => {
            setIsLoading(true);
            if (latitude !== 0 && longitude !== 0) {
                setInitialized(true);
                setIsLoading(false);
            } else {
                //  If no init latitude and longitude given, search through Geocoding API
                if (address) {
                    //  Geocoding API
                    const response = await Geocode.fromAddress(address);
                    if (response.results.length > 0) {
                        const {lat, lng} = response.results[0].geometry.location;
                        setLatitude(lat);
                        setLongitude(lng);
                        setIsLoading(false);
                        return;
                    } else {
                        Sentry.captureException(new Error('Cannot get location from Google Geocoding API'));
                    }
                }
                //  If no address or not from Google API
                if (!isEmpty(currentPosition)) {
                    setLatitude(currentPosition.latitude);
                    setLongitude(currentPosition.longitude);
                    setIsLoading(false);
                } else {
                    updateCurrentPosition();
                }
            }
        };
        //  Init location
        if (!initialized) {
            initData().then(() => setInitialized(true));
        }
    }, [initialized, address, latitude, longitude, currentPosition]);

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            {isLoading ? <SpinnerOverlay/> : ""}
            <Toast isOpen={toastMessage !== null}
                   style={{
                       backgroundColor: "#c13637",
                       maxWidth: "100%",
                       flexBasis: 0,
                       fontSize: "24px",
                   }}>
                <ToastHeader className="text-primary" toggle={() => setToastMessage(null)}>{toastMessage}</ToastHeader>
                <ToastBody style={{height: 0, padding: 0}}/>
            </Toast>
            <ModalHeader toggle={toggle}>
                <div>記錄位置 - {address}</div>
                <Button className="btn-light mt-2"
                        onClick={() => updateCurrentPosition(true)}>
                    (不準確？按此跳至目前GPS定位) <i className="fas fa-map-marker-alt text-danger"/>
                </Button>
                
            </ModalHeader>
            <ModalBody className="p-0">
                <div className="square d-flex justify-content-center align-content-center">
                    {initialized ?
                    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                                region="HK"
                                language="zh-HK">
                        <GoogleMap mapContainerStyle={{position: "absolute", width: "100%", height: "100%"}}
                                   zoom={17}
                                   center={{lat: latitude, lng: longitude}}
                                   onLoad={onLoad}
                                   onCenterChanged={onCenterChanged}
                                   onUnmount={onUnmount}/>
                    </LoadScript> : ""}
                    <i className="fas fa-map-marker-alt text-danger align-self-center"
                       style={{position: "relative", top: "-24px", fontSize: "48px", zIndex: 10}}/>
                </div>
            </ModalBody>
            <ModalFooter className="justify-content-between">
                <Button color="success"
                        style={{
                            backgroundColor: "#25B366",
                            borderColor: "#25B366"
                        }}
                        onClick={() => goToUri(`whatsapp://send?text=https%3A%2F%2Fwww.google.com%2Fmaps%3Fq%3D${latitude}%2C${longitude}%26z%3D17`)}>
                    <i className="fab fa-whatsapp" /> WhatsApp 分享
                </Button>
                <Button color="info"
                        onClick={() => goToUri(`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}&travelmode=driving`)}>
                    <i className="fas fa-directions" /> 跳至導航
                </Button>
                <Button color="success" onClick={() => {
                    toggle();
                    onLocationSelected({
                        latitude: map.center.lat(),
                        longitude: map.center.lng(),
                    });
                }}>記錄</Button>
            </ModalFooter>
        </Modal>
    );
}

export default DeliveryRecordGoogleMapModal;
