import React, {useRef} from 'react';
import DatePicker from "react-datepicker";
import Typography from '@material-ui/core/Typography';
import {
    Button, ButtonGroup, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
    Input, InputGroup, InputGroupAddon, InputGroupText, Modal, ModalBody, ModalFooter, ModalHeader, Row,
} from "reactstrap";
import SpinnerOverlay from "./components/SpinnerOverlay";
import {isEmpty, sleep} from "../functions/Utility";
import {
    getAllCarMaintenanceTypesFromCache, getAllCarsFromCache, getCarMaintenanceStatusColor, createCarMaintenance, sortCarsByCarID,
} from "../functions/Car";
import CarMaintenanceDetailReceiptRow from "./CarMaintenanceDetailReceiptRow";
import {isMobile} from "react-device-detect";


function CarMaintenanceNewRecordModal(props) {
    const {isOpen, toggle, data} = props;
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    //  Temp fix to update screen
    const [updateCount, setUpdateCount] = React.useState(0);
    //  Screen loading
    const [isLoading, setIsLoading] = React.useState(false);
    //  Main detail edit
    const [mainDetail, setMainDetail] = React.useState('');
    const onMainDetailChange = (e) => {
        Object.assign(data, {mainDetail: e.target.value});
        setMainDetail(e.target.value);
    };
    //  Dropdown toggles
    const [carDropdownOpen, setCarDropdownOpen] = React.useState(false);
    const [categoryDropdownOpen, setCategoryDropdownOpen] = React.useState(false);
    //  General data update function
    const onDataUpdate = async (updateData) => {
        Object.assign(data, updateData);
        setUpdateCount(updateCount + 1);
    };
    //  Date change
    const datePickerRef = useRef(null);
    const [mainDate, setMainDate] = React.useState(today);
    const onDateChange = async (date) => {
        data.mainDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        setMainDate(date);
    };
    //  Maintenance Fee
    const mainFeeRef = useRef(null);
    const [mainFee, setMainFee] = React.useState(0);
    const [mainFeeIsError, setMainFeeIsError] = React.useState(false);   //  State of input
    //  Save record
    const onSaveRecord = async () => {
        setIsLoading(true);
        await createCarMaintenance(data);
        await sleep(500);
        setIsLoading(false);
        toggle();
    };

    //  Initial API call and is called when data is changed
    React.useEffect(() => {
        if (isEmpty(data)) return;
        setMainDetail(data.mainDetail);
        data.mainDate = `${mainDate.getFullYear()}-${mainDate.getMonth() + 1}-${mainDate.getDate()}`;
        if (isMobile && datePickerRef.current !== null) {
            datePickerRef.current.input.readOnly = true;
        }
    }, [data, mainDate, datePickerRef]);

    return data ? (
        <Modal isOpen={isOpen} toggle={toggle}>
            {isLoading ? <SpinnerOverlay/> : ""}
            <ModalHeader toggle={toggle}>
                <InputGroup>
                    <Input type="textarea" value={mainDetail} onChange={onMainDetailChange}/>
                </InputGroup>
                <Typography color="textSecondary">
                    <strong className="bg-warning p-1 text-dark">{data.carID}</strong>{' '}
                    <strong className={"bg-" + getCarMaintenanceStatusColor(data.mainStatus) + " p-1 text-dark"}>{data.mainStatus}</strong>{' '}
                    {data.mainType}
                </Typography>
            </ModalHeader>
            <ModalBody>
                <Container>
                    <Row className="mb-2">
                        <Dropdown className="mr-2 d-inline" isOpen={carDropdownOpen}
                                  toggle={() => setCarDropdownOpen(prevState => !prevState)}>
                            <DropdownToggle caret color="warning">
                                更改車牌
                            </DropdownToggle>
                            <DropdownMenu>
                                {sortCarsByCarID(getAllCarsFromCache()).map((car) => (
                                    <DropdownItem key={`${data.mainID}-dropdown-car-${car.carID}`}
                                                  onClick={() => onDataUpdate({carID: car.carID})}>{car.carID}</DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        <ButtonGroup className="mr-2">
                            <Button color={data.mainStatus === '排期' ? "info" : "secondary"}
                                    style={data.mainStatus !== '排期' ? {opacity: 0.5} : {}}
                                    outline={data.mainStatus !== '排期'}
                                    onClick={() => onDataUpdate({mainStatus: '排期'})}>
                                排期
                            </Button>
                            <Button color={data.mainStatus === '完成' ? "success" : "secondary"}
                                    style={data.mainStatus !== '完成' ? {opacity: 0.5} : {}}
                                    outline={data.mainStatus !== '完成'}
                                    onClick={() => onDataUpdate({mainStatus: '完成'})}>
                                完成
                            </Button>
                        </ButtonGroup>
                        <Dropdown className="mr-2 d-inline" isOpen={categoryDropdownOpen}
                                  toggle={() => setCategoryDropdownOpen(prevState => !prevState)}>
                            <DropdownToggle caret color="secondary">
                                更改類別
                            </DropdownToggle>
                            <DropdownMenu>
                                {getAllCarMaintenanceTypesFromCache().map((carMainType, index) => (
                                    <DropdownItem key={`${data.mainID}-dropdown-car-main-type-${carMainType}`}
                                                  onClick={() => onDataUpdate({mainType: carMainType})}>{carMainType}</DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </Row>
                    <Row className="mb-2">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>維修日期</InputGroupText>
                            </InputGroupAddon>
                            <DatePicker ref={datePickerRef}
                                        selected={mainDate}
                                        onChange={onDateChange}
                                        dateFormat="yyyy/MM/dd"/>
                        </InputGroup>
                    </Row>
                    <Row className="mb-2">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>維修費用</InputGroupText>
                            </InputGroupAddon>
                            <Input ref={mainFeeRef}
                                   style={mainFeeIsError ? {color: "#c13637", borderColor: "#c13637"} : {}}
                                   type="text"
                                   inputMode="decimal"
                                   value={mainFee}
                                   onChange={(e) => {
                                       if (e.target.value === "" || isNaN(e.target.value)) {
                                           setMainFeeIsError(true);
                                       } else {
                                           setMainFeeIsError(false);
                                       }
                                       setMainFee(e.target.value);
                                       data.mainFee = e.target.value;
                                   }}/>
                        </InputGroup>
                    </Row>
                    <CarMaintenanceDetailReceiptRow data={data}
                                                    initReceiptImageBase64={""}
                                                    onChangeStart={() => {setIsLoading(true)}}
                                                    onChangeEnd={(imageBase64) => {
                                                        setIsLoading(false);
                                                        data.mainReceiptImageBase64Content = imageBase64;
                                                    }}/>
                </Container>
            </ModalBody>
            <ModalFooter>
                <Button color={mainFeeIsError ? "secondary" : "success"} disabled={mainFeeIsError} onClick={onSaveRecord}><i className="fas fa-check"/> 儲存</Button>
            </ModalFooter>
        </Modal>
    ) : <div/>;
}

export default CarMaintenanceNewRecordModal
