import React from 'react';
import {Col, Container, Input, Label, Row} from "reactstrap";

function DeliveryRecordStatusFilter(props) {
    const {onFilterChange} = props;
    const initState = {
        scheduled: true,
        prepared: true,
        onTheWay: true,
        complete: true,
    };
    const [deliveryStatusList, setDeliveryStatusList] = React.useState(initState);

    function onItemClick(itemKey) {
        let statusList = Object.assign({}, deliveryStatusList);
        statusList[itemKey] = !statusList[itemKey]; //  Flip selected checkbox state
        setDeliveryStatusList(statusList);
        //  Transfer status to outer component
        onFilterChange(statusList);
    }

    return (
        <Container>
            <Row className="mt-1">
                <Col xs={3} className="p-1">
                    <div className="bg-info p-2 text-dark text-center">
                        <Label check>
                            <Input type="checkbox"
                                   onChange={() => onItemClick('scheduled')}
                                   checked={deliveryStatusList.scheduled}/>{' '}
                            <strong>排程中</strong>
                        </Label>
                    </div>
                </Col>
                <Col xs={3} className="p-1">
                    <div className="bg-danger p-2 text-dark text-center">
                        <Label check>
                            <Input type="checkbox"
                                   onChange={() => onItemClick('prepared')}
                                   checked={deliveryStatusList.prepared}/>{' '}
                            <strong>準備好</strong>
                        </Label>
                    </div>
                </Col>
                <Col xs={3} className="p-1">
                    <div className="bg-warning p-2 text-dark text-center">
                        <Label check>
                            <Input type="checkbox"
                                   onChange={() => onItemClick('onTheWay')}
                                   checked={deliveryStatusList.onTheWay}/>{' '}
                            <strong>送緊貨</strong>
                        </Label>
                    </div>
                </Col>
                <Col xs={3} className="p-1">
                    <div className="bg-success p-2 text-dark text-center">
                        <Label check>
                            <Input type="checkbox"
                                   onChange={() => onItemClick('complete')}
                                   checked={deliveryStatusList.complete}/>{' '}
                            <strong>完成</strong>
                        </Label>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default DeliveryRecordStatusFilter
