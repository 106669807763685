import React from 'react';
import {
    Button,
    Collapse,
    Input,
    InputGroup,
    InputGroupAddon,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";

import SpinnerOverlay from "./components/SpinnerOverlay";
import {checkArticleBelongTo, createArticle, deleteCategory, reorderArticles} from "../functions/Wiki";
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import arrayMove from "array-move";


function WikiCategory(props) {
    const category = props.data;
    const recentArticleID = props.recentArticleID;
    const onCategoryDeleted = props.onCategoryDeleted;
    const isDeleting = props.isDeleting;
    const onArticleChange = props.onArticleChange;
    const onArticleCreated = props.onArticleCreated;
    const onArticleReordered = props.onArticleReordered;

    const [isLoading, setIsLoading] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(checkArticleBelongTo(category, recentArticleID));
    const [newArticle, setNewArticle] = React.useState("");
    const [isCreatingArticle, setIsCreatingArticle] = React.useState(false);

    //  Create new category
    const onNewArticleChange = (e) => {
        setNewArticle(e.target.value);
    };
    const onNewArticleSave = async () => {
        setIsLoading(true);
        await createArticle(category.categoryID, newArticle);
        await onArticleCreated();
        setIsLoading(false);
        setIsCreatingArticle(false);
        setNewArticle("");
    };
    //  Delete category
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const [deleteConfirmationText, setDeleteConfirmationText] = React.useState("");
    const onDeleteCategory = async (categoryID) => {
        setIsLoading(true);
        await deleteCategory(categoryID);
        setDeleteConfirmationText("");
        setIsDeleteModalOpen(false);
        setIsLoading(false);
        await onCategoryDeleted();
    };

    //  Dragging Wiki Article List
    const SortableWikiArticle = SortableElement(({value}) => (
        <div className="pl-4"
             key={value.articleID}
             style={value.articleID === recentArticleID ? {backgroundColor: "cornsilk", cursor: "pointer"} : {cursor: "pointer"}}
             onClick={() => onArticleChange(value)} >
            <Button color=""
                    className="text-left"
                    onClick={() => onArticleChange(value)}>
                {value.title}
            </Button>
        </div>
    ));
    const WikiArticleSortableList = SortableContainer(({items}) => {
        return (
            <ul className={"pl-2"}>
                {items.map((value, index) => (
                    <SortableWikiArticle key={`wiki-sortable-article-${index}`} index={index} value={value} />
                ))}
            </ul>
        );
    });
    const onArticleReorder = async ({oldIndex, newIndex}) => {
        let reorderedData = arrayMove(category.articles, oldIndex, newIndex);
        setIsLoading(true);
        await reorderArticles(reorderedData);
        onArticleReordered(reorderedData);
        setIsLoading(false);
    };

    React.useEffect(() => {
        //  Check if some article of this category is open,
        //  If yes, open the collapsed category
        let isSomeArticleOpen = checkArticleBelongTo(category, recentArticleID);
        setIsOpen(isSomeArticleOpen);
    }, [recentArticleID]);

    return (
        <div className={"justify-content-between mb-1"}>
            {isLoading ? <SpinnerOverlay /> : ""}
            {isDeleting ?
                <div style={{cursor: "pointer"}}>
                    <Button onClick={() => setIsDeleteModalOpen(true)} color="danger">
                        <i className="fas fa-trash"/>{" "}
                        <span className="ml-3">{category.name}</span>
                    </Button>
                </div> :
                <>
                    <div onClick={() => setIsOpen(!isOpen)} style={{cursor: "pointer"}}>
                        <Button color="">
                            <i className={
                                isDeleting ? "fas fa-bars" :
                                isOpen ? "fas fa-chevron-up" : "fas fa-chevron-down"}/>{" "}
                            <span className="ml-3">{category.name}</span>
                        </Button>
                    </div>
                    <Collapse isOpen={isOpen}>
                        <WikiArticleSortableList items={category.articles}
                                                 onSortEnd={onArticleReorder}
                                                 pressDelay={200}/>
                        <div className="pl-4">
                            <Button color=""
                                    className={"text-info"}
                                    onClick={() => setIsCreatingArticle(!isCreatingArticle)}>
                                {!isCreatingArticle ?
                                    <>
                                        <i className="fas fa-plus"/> 新增篇章
                                    </> :
                                    <>
                                        <i className="fas fa-times"/> 取消
                                    </>
                                }
                            </Button>
                            {isCreatingArticle ?
                                <InputGroup>
                                    <Input type="text" value={newArticle} onChange={onNewArticleChange}/>
                                    <InputGroupAddon addonType="append">
                                        <Button color="success" onClick={onNewArticleSave}>
                                            <i className="fas fa-check"/>
                                        </Button>
                                    </InputGroupAddon>
                                </InputGroup> : ""
                            }
                        </div>
                    </Collapse>
                </>
            }
            <Modal isOpen={isDeleteModalOpen} toggle={() => setIsDeleteModalOpen(false)}>
                <ModalHeader toggle={() => setIsDeleteModalOpen(false)}>確認刪除？此操作無法復原！</ModalHeader>
                <ModalBody>
                    請在以下位置輸入：{category.name}
                    <Input type="text"
                           style={{height: "48px"}}
                           value={deleteConfirmationText}
                           onChange={(e) => setDeleteConfirmationText(e.target.value)}/>
                </ModalBody>
                <ModalFooter className="justify-content-between">
                    <Button color={category.name === deleteConfirmationText ? "danger" : "secondary"}
                            disabled={category.name !== deleteConfirmationText}
                            onClick={() => onDeleteCategory(category.categoryID)}>
                        刪除
                    </Button>{' '}
                    <Button color="secondary" onClick={() => setIsDeleteModalOpen(false)}>取消</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default WikiCategory
