import React from "react";
import PullToRefresh from "rmc-pull-to-refresh";
import {Row, Spinner} from "reactstrap";

function ScreenPullToRefresh(props) {
    const {disabled} = props;
    const [isRefreshing, setIsRefreshing] = React.useState(false);
    return (
        disabled ? <div style={{marginTop: "64px"}}>
            {props.children}
        </div> : <PullToRefresh
            direction="down"
            getScrollContainer={() => document.body}
            refreshing={isRefreshing}
            onRefresh={async () => {
                setIsRefreshing(true);
                await props.onRefresh();
                setIsRefreshing(false);
            }}
            indicator={{
                activate: (
                    <Row className="p-2 mb-3">
                        <i className="fas fa-angle-double-down w-100 text-center text-primary"
                           style={{fontSize: "32px"}}/>
                    </Row>
                ),
                deactivate: (
                    <Row className="p-2 mb-3">
                        <i className="fas fa-angle-double-down w-100 text-center text-primary"
                           style={{fontSize: "32px"}}/>
                    </Row>
                ),
                release: (
                    <Row className="p-2 mb-3 justify-content-center">
                        <Spinner className="text-center" color="primary"/>
                    </Row>
                ),
                finish: (
                    <Row className="p-2 mb-3">
                        <i className="fas fa-check w-100 text-center text-primary"
                           style={{fontSize: "32px"}}/>
                    </Row>
                ),
            }}
            distanceToRefresh={56}
            damping={150}>
            {props.children}
        </PullToRefresh>
    );
}

export default ScreenPullToRefresh;