import React from 'react'
import { Redirect } from "react-router-dom";
import { Col, Container, Row, Spinner } from "reactstrap";
import { sort } from "fast-sort";

import NavSidebar from "./components/NavSidebar";
import { isLoggedIn } from "../functions/Auth";
import AppPage from "../AppPage";
import { filterGoods, getAllGoodsRecords, getGoodsRecord } from "../functions/Goods";
import { getAllSalesStat, filterSalesStat, sortSalesStat } from "../functions/SalesStat";
import SalesStatCard from "./SalesStatCard";
import SalesStatDetailModal from "./SalesStatDetailModal";

import SearchBar from "./components/SearchBar";
import SpinnerOverlay from "./components/SpinnerOverlay";
import ScreenPullToRefresh from "./components/ScreenPullToRefresh";
import { isEmpty } from "../functions/Utility";
import SearchDateRangeBar from "./components/SearchDateRangeBar";
import SearchSortingBar from "./components/SearchSortingBar";


function SalesStatScreen() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    //  Initial loading
    const [initialized, setInitialized] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    //  Data
    const [salesStatData, setSalesStatData] = React.useState([]);
    const [displayData, setDisplayData] = React.useState([]);
    const [singleDailySalesData, setSingleDailySalesData] = React.useState({});


    const [searchStartDate, setSearchStartDate] = React.useState(new Date(new Date().setDate(today.getDate() - 7)));
    const [searchEndDate, setSearchEndDate] = React.useState(today);

    //  Sorting
    const [sortColumnName, setSortColumnName] = React.useState('date');
    const [sortOrder, setSortOrder] = React.useState(-1);   //  Descending

    //  Sorting
    const columnsToSort = [
        {
            columnName: "date",
            name: "日期"
        },
        {
            columnName: "salesVolume",
            name: "金額"
        },
        {
            columnName: "deliveryCount",
            name: "送貨"
        }
    ];

    //  Item click and modal exit toggle
    async function onItemClick(singleDailySalesData) {
        setIsLoading(true);
        setSingleDailySalesData(singleDailySalesData);
        setIsLoading(false);
    }


    async function onModalToggle() {
        setSingleDailySalesData({});
    }

    //  Pull down to refresh
    async function onPullDownRefresh() {
        let salesStatData;
        salesStatData = await getAllSalesStat();

        setSalesStatData(salesStatData);
    }

    //  Run when state value changes
    React.useEffect(() => {
        //  Initial API call
        const initData = async () => {
            let salesStatData;
            salesStatData = await getAllSalesStat();
            setIsLoading(false);
            setSalesStatData(salesStatData);
        };
        if (!initialized) {
            initData().then(() => setInitialized(true));
        }
        //  Filter and sort=
        let filteredData = filterSalesStat(salesStatData, searchStartDate, searchEndDate);
        let sortedData = sortSalesStat(filteredData, sortColumnName, sortOrder);
        setDisplayData(sortedData);
    }, [initialized, salesStatData, searchStartDate, searchEndDate, sortColumnName, sortOrder]);

    return isLoggedIn() ? (
        <Container className="pl-3 pr-3">
            {isLoading ? <SpinnerOverlay /> : ""}
            <NavSidebar />
            <ScreenPullToRefresh onRefresh={async () => await onPullDownRefresh()}
                disabled={!isEmpty(singleDailySalesData)}>
                <SearchDateRangeBar onStartDateChange={(date) => { setSearchStartDate(date) }}
                    onEndDateChange={(date) => setSearchEndDate(date)}
                    defaultStartDate={searchStartDate}
                    defaultEndDate={searchEndDate} />
                <SearchSortingBar columnsToSort={columnsToSort}
                    defaultColumn={columnsToSort[0]}
                    onSortChange={(columnName, order) => {
                        setSortColumnName(columnName);
                        setSortOrder(order);
                    }} />
                <Row className="bg-secondary mt-2 p-3">
                    <Col xs={12} className="mb-2">
                        <strong className={"bg-primary p-1 text-light"}>總數</strong>{' '}
                        {displayData ? displayData.length : <Spinner size="sm" color="primary" /> }
                    </Col>
                    <Col xs={3} >
                        <strong className={"bg-info p-1 text-dark"}>總金額</strong>{' '}
                        ${displayData ? displayData.reduce(function (previousValue, currentValue) { return previousValue + currentValue.salesVolume}, 0)  : <Spinner size="sm" color="info" />}
                    </Col>
                    <Col xs={3}>
                        <strong className={"bg-warning p-1 text-dark"}>平均</strong>{' '}
                        ${displayData ? Math.round(displayData.reduce(function (previousValue, currentValue) { return previousValue + currentValue.salesVolume}, 0) / displayData.length)   : <Spinner size="sm" color="info" />}
                    </Col>
                    <Col xs={3}>
                        <strong className={"bg-success p-1 text-dark"}>總送貨</strong>{' '}
                        {displayData ? displayData.reduce(function (previousValue, currentValue) { return previousValue + currentValue.deliveryCount}, 0)  : <Spinner size="sm" color="info" />}車
                    </Col>
                    <Col xs={3}>
                        <strong className={"bg-success p-1 text-dark"}>平均送貨</strong>{' '}
                        {displayData ? Math.round(displayData.reduce(function (previousValue, currentValue) { return previousValue + currentValue.deliveryCount}, 0) / displayData.length)   : <Spinner size="sm" color="info" />}車
                    </Col>
                </Row>
                <Row>
                    {displayData.map((singleDailySalesData, index, array) => {
                        return (
                            <Col key={singleDailySalesData.date}
                                data-id={singleDailySalesData.date}
                                xs={12} sm={12} md={6} lg={4}>
                                <SalesStatCard data={singleDailySalesData}
                                    onDetailClick={() => onItemClick(singleDailySalesData)}
                                    onUpdateStart={() => setIsLoading(true)}
                                    onUpdateEnd={() => setIsLoading(false)} />
                            </Col>
                        );
                    })}
                </Row>
            </ScreenPullToRefresh>
            <SalesStatDetailModal isOpen={!isEmpty(singleDailySalesData)}
                toggle={onModalToggle}
                data={singleDailySalesData} />
        </Container>
    ) : (
        <Redirect to={AppPage.login.route} />
    )
}

export default SalesStatScreen
