import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
    Button, Card, CardBody,  Input, InputGroup, InputGroupAddon, InputGroupText,
    Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";
import { isEmpty} from "../functions/Utility";
import * as Sentry from "@sentry/react";


function SalesStatDetailModal(props) {
    const {isOpen, toggle, data} = props;

    return !isEmpty(data) ? (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                <div className="pt-2 pb-2">
                    {data.date}
                </div>
                <Typography color="textSecondary">
                    {data.salesVolume} - {data.salesVolume}
                </Typography>
            </ModalHeader>
            <ModalBody>
                <Card style={{border: "none"}}>
                    <CardBody className="mb-2">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>銷售額</InputGroupText>
                            </InputGroupAddon>
                            <Input type="number" value={data.salesVolume} readOnly={true}/>
                        </InputGroup>
                    </CardBody>

                </Card>
            </ModalBody>
            <ModalFooter className="justify-content-between">
                <Button color="primary" onClick={toggle}>OK</Button>
            </ModalFooter>
        </Modal>
    ) : <div/>;
}

export default SalesStatDetailModal
