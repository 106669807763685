import React from 'react'
import {useHistory} from "react-router-dom";
import {
    Breadcrumb, BreadcrumbItem, Button, Col, Container, Input, InputGroup,
    ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Modal, ModalBody, ModalFooter, ModalHeader, Row
} from "reactstrap";
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/plugins/image.min.js';
// import 'froala-editor/js/languages/zh_tw.js';
import './components/froala-editor/zh_tw.js'; //  Patch
import FroalaEditorComponent from 'react-froala-wysiwyg';
import ReactHtmlParser from 'react-html-parser';

import '../App.css'
import NavSidebar from "./components/NavSidebar";
import {
    deleteArticle, deleteCategory,
    findArticle,
    findArticleByName,
    getAllArticles,
    searchArticle,
    updateArticle
} from "../functions/Wiki";
import SpinnerOverlay from "./components/SpinnerOverlay";
import AppPage from "../AppPage";
import SearchBar from "./components/SearchBar";
import {htmlToText} from "html-to-text";
import WikiCategoryMenu from "./WikiCategoryMenu";


function WikiScreen(props) {
    let history = useHistory();
    let {category, article} = props.match ? props.match.params : {};
    //  Initial loading
    const [initialized, setInitialized] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [articleData, setArticleData] = React.useState([]);
    //  Search bar
    const [searchString, setSearchString] = React.useState("");
    const [searchResult, setSearchResult] = React.useState([]);
    //  Update article
    const [isEditingArticle, setIsEditingArticle] = React.useState(false);
    const [editingArticleTitle, setEditingArticleTitle] = React.useState("");
    const [editingArticleContent, setEditingArticleContent] = React.useState("");
    const [recentArticle, setRecentArticle] = React.useState(null);
    const [isSaveModalOpen, setIsSaveModalOpen] = React.useState(false);
    //  Update article
    const onUpdateArticle = async () => {
        let allArticles;
        setIsLoading(true);
        await updateArticle({
            articleID: recentArticle.articleID,
            title: editingArticleTitle,
            content: editingArticleContent,
        });
        allArticles = await reloadArticles();
        //  Show recent view article from the refreshed article list
        setRecentArticle(findArticle(recentArticle.articleID, allArticles));
        setIsLoading(false);
        setIsEditingArticle(false);
    };
    //  Delete article
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const [deleteConfirmationText, setDeleteConfirmationText] = React.useState("");
    const onDeleteArticle = async () => {
        let allArticles, firstArticle;
        setIsLoading(true);
        await deleteArticle(recentArticle.articleID);
        allArticles = await reloadArticles();
        setDeleteConfirmationText("");
        setIsDeleteModalOpen(false);
        setIsLoading(false);
        //  Go to first article of all
        firstArticle = getFirstArticle(allArticles);
        setRecentArticle(firstArticle);
        history.push(`${AppPage.wiki.route}/${firstArticle.categoryName}/${firstArticle.title}`)
    };

    const reloadArticles = async () => {
        let allArticles = await getAllArticles();
        allArticles.sort((a, b) => {
            return b.priority - a.priority; //  Descending priority
        });
        allArticles.forEach((category) => {
            category.articles.sort((a, b) => {
                return b.priority - a.priority; //  Descending priority
            });
        });
        setArticleData(allArticles);
        return allArticles;
    };
    const getFirstArticle = (allArticles) => {
        let firstCategory, firstArticle = {title: "", content: ""};
        for (let i = 0; i < allArticles.length; i++) {
            firstCategory = allArticles[i];
            if (firstCategory.articles.length === 0) {
                continue;
            }
            firstArticle = firstCategory.articles[0];
            break;
        }
        return firstArticle;
    };

    React.useEffect(() => {
        //  Initial API call
        const initData = async () => {
            let allArticles, firstArticle;
            setIsLoading(true);
            allArticles = await reloadArticles();
            //  First category and first article as default page
            if (category && article) {
                firstArticle = findArticleByName(category, article, allArticles);
            } else {
                firstArticle = getFirstArticle(allArticles);
            }
            setRecentArticle(firstArticle);
            setIsLoading(false);
        };
        if (!initialized) {
            initData().then(() => setInitialized(true));
        }
        let result = searchArticle(searchString, articleData);
        setSearchResult(result);
    }, [searchString]);

    return (
        <Container className="pl-3 pr-3">
            <div style={{marginTop: "56px"}}/>
            {isLoading ? <SpinnerOverlay /> : ""}
            <NavSidebar/>
            <div className={"mt-2 mb-2"}>
                <SearchBar searchValue={searchString}
                           onSearchChange={(search) => setSearchString(search)}
                           placeholder={"搜尋（標題／內容）"}/>
                <ListGroup>
                    {searchResult.map((resultArticle) => {
                        let displayContent = htmlToText(resultArticle.content, {wordwrap: 120}).toLowerCase();
                        let firstIndexOfSearch = displayContent.indexOf(searchString.toLowerCase());
                        displayContent = displayContent.substr(Math.max(firstIndexOfSearch - 10, 0));
                        displayContent = displayContent.replace(searchString.toLowerCase(), `<span style="background-color: lemonchiffon">${searchString.toLowerCase()}</span>`);
                        return (
                            <ListGroupItem action
                                           onClick={() => {
                                               if (isEditingArticle) {
                                                   if (editingArticleTitle !== recentArticle.title || editingArticleContent !== recentArticle.content) {
                                                       setIsSaveModalOpen(true);
                                                       return;
                                                   }
                                               }
                                               //   Get the latest data
                                               resultArticle = findArticle(resultArticle.articleID, articleData);
                                               setRecentArticle(resultArticle);
                                               setEditingArticleTitle(resultArticle.title);
                                               setEditingArticleContent(resultArticle.content);
                                               setSearchString("");
                                           }}>
                                <ListGroupItemHeading>{resultArticle.title}</ListGroupItemHeading>
                                <ListGroupItemText className={"m-0"}
                                                   style={{maxHeight: "96px", overflow: "hidden"}} >
                                    {ReactHtmlParser(displayContent)}
                                </ListGroupItemText>
                            </ListGroupItem>
                        );
                    })}
                </ListGroup>
            </div>
            <Row>
                <Col xs={12} sm={3} className={"mt-2"}>
                    <WikiCategoryMenu articleData={articleData}
                                      recentArticle={recentArticle}
                                      onArticleChange={(article) => {
                                          if (isEditingArticle) {
                                              if (editingArticleTitle !== recentArticle.title || editingArticleContent !== recentArticle.content) {
                                                  setIsSaveModalOpen(true);
                                                  return;
                                              }
                                          }
                                          setRecentArticle(article);
                                          setEditingArticleTitle(article.title);
                                          setEditingArticleContent(article.content);
                                          history.push(`${AppPage.wiki.route}/${article.categoryName}/${article.title}`)
                                      }}
                                      onArticleCreated={async () => await reloadArticles()}
                                      onCategoryCreated={async () => await reloadArticles()}
                                      onCategoryDeleted={async () => {
                                          let allArticles, firstArticle;
                                          //  Go to first article of all
                                          allArticles = await reloadArticles();
                                          firstArticle = getFirstArticle(allArticles);
                                          setRecentArticle(firstArticle);
                                          history.push(`${AppPage.wiki.route}/${firstArticle.categoryName}/${firstArticle.title}`)
                                      }}
                                      onCategoryReordered={(articleData) => setArticleData(articleData)}
                                      onArticleReordered={(articleList) => {
                                          let articleDataCloned = articleData.slice(0);
                                          let categoryID = articleList[0].categoryID;
                                          for (let i = 0; i < articleData.length; i++) {
                                              let category = articleData[i];
                                              if (category.categoryID === categoryID) {
                                                  // Replace the reordered article list
                                                  articleDataCloned[i].articles = articleList;
                                              }
                                          }
                                          setArticleData(articleDataCloned)
                                      }}/>
                </Col>
                <Col xs={12} sm={9} className={"mt-2"}>
                    <Container className="d-grid gap-2 d-flex justify-content-between">
                        {isEditingArticle ?
                            <>
                                <Button color="success"
                                        onClick={onUpdateArticle}>
                                    <i className="fas fa-check"/> 儲存
                                </Button>
                                <Button color="secondary"
                                        onClick={() => setIsEditingArticle(false)}>
                                    <i className="fas fa-times"/> 取消
                                </Button>
                            </> :
                            <>
                                <Button color=""
                                        style={{backgroundColor: "cornsilk", borderColor: "cornsilk"}}
                                        onClick={() => {
                                            setIsEditingArticle(!isEditingArticle);
                                            setEditingArticleTitle(recentArticle.title);
                                            setEditingArticleContent(recentArticle.content);
                                        }}>
                                    <i className="fas fa-pen"/> 修改
                                </Button>
                                <Button color="danger"
                                        onClick={() => setIsDeleteModalOpen(true)}>
                                    <i className="fas fa-trash"/> 刪除
                                </Button>
                            </>
                        }
                    </Container>
                    <Container className={"w-100 mt-3"}>
                        <div id="wiki-editor"/>
                        {isEditingArticle ?
                            <>
                                <InputGroup className={"mb-3"}>
                                    <Input type="text"
                                           style={{height: "48px"}}
                                           value={editingArticleTitle}
                                           onChange={(e) => {
                                               setEditingArticleTitle(e.target.value);
                                           }}/>
                                </InputGroup>
                                <FroalaEditorComponent
                                    tag='wiki-editor'
                                    config={{
                                        placeholderText: '開始！',
                                        toolbarButtons: {
                                            'moreText': {
                                                'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
                                            },
                                            'moreParagraph': {
                                                'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
                                            },
                                            'moreRich': {
                                                'buttons': ['insertImage', 'insertLink', 'insertTable', 'emoticons']
                                            },
                                            'moreMisc': {
                                                'buttons': ['undo', 'redo', 'fullscreen', 'print', 'spellChecker', 'selectAll', 'html', 'help']
                                            }
                                        },
                                        language: "zh_tw",
                                        charCounterCount: true,
                                        imageUploadParam: 'image',
                                        imageUploadURL: '/api/images',
                                        imageUploadMethod: 'POST',
                                        imageMaxSize: 5 * 1024 * 1024,  // Set max image size to 5MB.
                                    }}
                                    model={editingArticleContent}
                                    onModelChange={(model) => {
                                        setEditingArticleContent(model);
                                    }} />
                            </> :
                            <>
                                <Breadcrumb>
                                    <BreadcrumbItem><i className="fas fa-home"/></BreadcrumbItem>
                                    <BreadcrumbItem>{recentArticle ? recentArticle.categoryName : ""}</BreadcrumbItem>
                                    <BreadcrumbItem>{recentArticle ? recentArticle.title : ""}</BreadcrumbItem>
                                </Breadcrumb>
                                {recentArticle ? ReactHtmlParser(recentArticle.content) : ""}
                            </>
                        }
                    </Container>
                </Col>
            </Row>
            {recentArticle ?
                <Modal isOpen={isDeleteModalOpen} toggle={() => setIsDeleteModalOpen(false)}>
                    <ModalHeader toggle={() => setIsDeleteModalOpen(false)}>確認刪除？此操作無法復原！</ModalHeader>
                    <ModalBody>
                        請在以下位置輸入：{recentArticle.title}
                        <Input type="text"
                               style={{height: "48px"}}
                               value={deleteConfirmationText}
                               onChange={(e) => setDeleteConfirmationText(e.target.value)}/>
                    </ModalBody>
                    <ModalFooter className="justify-content-between">
                        <Button color={recentArticle.title === deleteConfirmationText ? "danger" : "secondary"}
                                disabled={recentArticle.title !== deleteConfirmationText}
                                onClick={onDeleteArticle}>
                            刪除
                        </Button>{' '}
                        <Button color="secondary" onClick={() => setIsDeleteModalOpen(false)}>取消</Button>
                    </ModalFooter>
                </Modal> : ""}
            <Modal isOpen={isSaveModalOpen} toggle={() => setIsSaveModalOpen(false)}>
                <ModalHeader toggle={() => setIsSaveModalOpen(false)}>尚未儲存資料！要儲存嗎？</ModalHeader>
                <ModalFooter className="justify-content-between">
                    <Button color="success" onClick={async () => {
                        await onUpdateArticle();
                        setIsSaveModalOpen(false);
                        setSearchString("");
                    }}>儲存</Button>{' '}
                    <Button color="secondary" onClick={() => setIsSaveModalOpen(false)}>取消</Button>
                </ModalFooter>
            </Modal>
        </Container>
    )
}

export default WikiScreen
