import React from 'react';
import Typography from '@material-ui/core/Typography';
import {Button, Card, CardImg, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {sort} from "fast-sort";
import QRCode from "qrcode";
import {getDeliveryStatusColor, splitDeliveryRemark} from "../functions/Delivery";
import {addPhoneLink, getImageType, isEmpty} from "../functions/Utility";
import {getBillPaymentImage, getBillStatusColor} from "../functions/Bill";
import SpinnerOverlay from "./DeliveryRecordDetailModal";


function BillRecordDetailModal(props) {
    const {isOpen, toggle, data} = props;

    const [isLoading, setIsLoading] = React.useState(false);
    //  QR code
    const [invoiceUrlQrCode, setInvoiceUrlQrCode] = React.useState("");
    //  Payment Image
    const [paymentImageData, setPaymentImageData] = React.useState([]);

    React.useEffect(() => {
        if (!data || isEmpty(data)) return;
        //  Initial API call
        const initData = async () => {
            let paymentImageData, invoiceQrCode;
            setIsLoading(true);
            paymentImageData = await getBillPaymentImage(data.paymentID);
            setPaymentImageData(paymentImageData);
            if (data.invoiceUrl) {
                invoiceQrCode = await QRCode.toDataURL(data.invoiceUrl);
                setInvoiceUrlQrCode(invoiceQrCode);
            }
            setIsLoading(false);
        };
        initData().then();
    }, [data]);

    return data ? (
        <Modal isOpen={isOpen} toggle={toggle}>
            {isLoading ? <SpinnerOverlay /> : ""}
            <ModalHeader toggle={toggle}>
                <div className="pt-2">
                    {addPhoneLink(data.customerName)}
                </div>
                <Typography color="textSecondary" className="pb-2">
                    <strong>$ {data.netPrice}</strong>
                </Typography>
                <Typography color="textSecondary">
                    <strong className={"bg-"+getBillStatusColor(data.billStatus)+" p-1 text-dark"}>{data.billStatus}</strong>{' '}
                    <strong className={"bg-warning p-1 text-dark"}>{data.paymentMethod}</strong>
                    {data.paymentImageCount > 0 ?
                        <strong className={"pl-2 text-success"}>
                            <i className="fas fa-file-invoice-dollar"/>
                        </strong> : ""}
                </Typography>
                <Typography color="textSecondary">
                    <strong>{data.paymentID}</strong>
                </Typography>
                {splitDeliveryRemark(data.remark).map((remark, index) => (
                    <Typography key={`${data.paymentID}-remark-${index}`} className="pl-2 pt-2 m-0 text-danger" color="textSecondary">
                        <strong>{addPhoneLink(remark)}</strong>
                    </Typography>
                ))}
            </ModalHeader>
            <ModalBody>
                <Container style={{
                    color: "black",
                    border: "1px solid rgba(0,0,0,.125)",
                    borderRadius: ".25rem",
                }}>
                    <Row className="bg-secondary pt-2 pb-2">
                        <Col xs={6}>項目內容</Col>
                        <Col xs={2} className="p-0">單件</Col>
                        <Col xs={2} className="p-0">數目</Col>
                        <Col xs={2} className="p-0">價格</Col>
                    </Row>
                    {data.goods.map((good) => (
                        <Row key={`${data.paymentID}-good-${good.goodID}`} className="pt-2 pb-2">
                            <Col xs={6}>
                                <div>{good.goodDescription}</div>
                                {good.remarks !== "" ? (
                                    <div className="pl-2 text-danger">{good.remarks}</div>
                                ) : ""}
                            </Col>
                            <Col xs={2} className="p-0">${good.unitPrice}</Col>
                            <Col xs={2} className="p-0">{good.quantity + " " + good.goodUnit}</Col>
                            <Col xs={2} className="p-0">${good.subItemPrice}</Col>
                        </Row>
                    ))}
                </Container>
                {data.customerPhoneList.length > 0 ? (
                    <Container className="mt-2" style={{
                        color: "black",
                        border: "1px solid rgba(0,0,0,.125)",
                        borderRadius: ".25rem",
                    }}>
                        <Row className="bg-secondary pt-2 pb-2">
                            <Col xs={6}>聯絡人</Col>
                            <Col xs={6} className="p-0">電話</Col>
                        </Row>
                        {sort(data.customerPhoneList).desc(u => u.priority).map((customerPhone) => (
                            <Row key={`${data.paymentID}-customer-phone-${customerPhone.phoneNumber}`}
                                 className="pt-2 pb-2">
                                <Col xs={6}>{customerPhone.phoneUserName}</Col>
                                <Col xs={6} className="p-0">
                                    {addPhoneLink(customerPhone.phoneNumber)}
                                </Col>
                            </Row>
                        ))}
                    </Container>
                ): ""}
                <Container>
                    <Row className="m-2 justify-content-center">
                        <a target="_blank" rel="noreferrer" href={data.invoiceUrl}>
                            <img src={invoiceUrlQrCode} alt="invoice-qr-code" />
                        </a>
                    </Row>
                </Container>
                <Typography className="mb-2 text-center" variant="h6" color="textSecondary" gutterBottom>
                    <strong className="text-dark">送貨記錄</strong>{' '}
                </Typography>
                <Container style={{
                    color: "black",
                    border: "1px solid rgba(0,0,0,.125)",
                    borderRadius: ".25rem",
                }}>
                    <Row style={{backgroundColor: "#d9e2ef"}} className="pt-2 pb-2">
                        <Col xs={5}>送貨時間</Col>
                        <Col xs={4} className="p-0">地址</Col>
                    </Row>
                    {data.deliveryRecords.map((delivery) => (
                        <Row key={`${data.paymentID}-delivery-${delivery.deliveryID}`} className="pt-2 pb-2">
                            <Col xs={5}>{delivery.proposedDateTime}</Col>
                            <Col xs={4} className="pl-0">{delivery.address}</Col>
                            <Col xs={2} className="p-0">
                                <strong className={"bg-"+getDeliveryStatusColor(delivery.deliveryStatus)+" p-1 text-dark"}>
                                    {delivery.deliveryStatus.substring(0, 2)}
                                </strong>
                            </Col>
                            <Col xs={1} className="p-0 text-dark">
                                <a href={delivery.deliveryNoteUrl}><i className="fas fa-qrcode"/></a>
                            </Col>
                        </Row>
                    ))}
                </Container>
                <Typography className="mt-3 mb-2 text-center" variant="h6" color="textSecondary" gutterBottom>
                    <strong className="text-dark">入數記錄</strong>{' '}
                </Typography>
                {paymentImageData.map((image) => (
                    <Card style={{border: "none"}}>
                        <CardImg bottom width="100%"
                                 src={`data:${getImageType(image.ImageBase64Content).mime};base64,${image.ImageBase64Content}`}
                                 alt={`bill-payment-${data.paymentID}-image`}/>
                    </Card>
                ))}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={toggle}>OK</Button>
            </ModalFooter>
        </Modal>
    ) : <div/>;
}

export default BillRecordDetailModal
