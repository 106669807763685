import React from 'react'
import {Button, Container, Navbar, NavbarText} from "reactstrap";
import {slide as Menu} from "react-burger-menu";

import '../App.css'
import AppPage from "../AppPage";
import {getNickname, getRights, logout} from "../functions/Auth";


function ErrorScreen() {
    function onLogoutClick(e) {
        e.preventDefault();
        logout();
        window.location.href = AppPage.login.route;
    }

    return (
        <Container className="pl-3 pr-3">
            <>
                <Navbar className="fixed-top justify-content-between" color="primary" light expand="md">
                    <Menu className="m-0">
                        <span className="menu-item p-3" onClick={ onLogoutClick }>
                        <i className="fas fa-sign-out-alt"/>
                        <span className="ml-2">登出</span>
                    </span>
                        <hr className="border-light"/>
                        <span className="menu-item--small p-3 text-white-50">版本：{process.env.REACT_APP_GIT_COMMIT_HASH}</span>
                    </Menu>
                    <NavbarText className="text-light">
                        <i className="fas fa-warning"/>{' '}
                        發生錯誤
                    </NavbarText>
                    <NavbarText className={"text-light pl-2 pr-2 bg-" + getRights()}>
                        {getNickname()}
                    </NavbarText>
                </Navbar>
                <div className="pb-2"/>
            </>
            <div className="w-100 d-flex justify-content-center"
                 style={{
                     position: "fixed",
                     top: "50%",
                     left: 0,
                     marginTop: "-24px",
                 }}>
                <Button color="success"
                        size="lg"
                        onClick={() => {window.location.href = AppPage.home.route;}}>
                    重新啟動
                </Button>
            </div>
        </Container>
    )
}

export default ErrorScreen
