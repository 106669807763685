import imageType from "image-type";
import React, { useState, useEffect } from 'react';
import Resizer from "react-image-file-resizer";

export const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

export const isEmpty = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const goToUri = (uri, name) => {
    let link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.target = "_blank";
    link.rel = "noreferrer";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const convertFileToBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

//this including the base 64 encoding
export const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1000, //in pixel
      1000, //in pixel
      "JPEG", // compressed format: PNG / JPEG
      50, // 50% quality
        0, //rotaion = 0
      (uri) => {
        resolve(uri); // a uri encoded in base 64
      },
      "base64" // blob
    );
  });

export const getImageType = (fileBase64) => {
    return fileBase64 ? imageType(Buffer.from(fileBase64, 'base64')) : null;
};

export const imageBase64ToFile = (fileBase64, name) => {
    const imageDataArray = Buffer.from(fileBase64, 'base64');
    return new File([imageDataArray.buffer], name, {type: imageType(imageDataArray).mime});
};

export const addPhoneLink = (text) => {
    if (!text) return '';
    if (text === '') return '';
    const matchArray = text.match(/[0-9]{8}|([0-9]{4}[ -][0-9]{4})/g);
    if (matchArray === null) return text;


    let textWithoutNumber = text;
    let textArray;
    let dialLinkArray = [];
    matchArray.forEach((number) => {
        let dialNumber, dialLink;
        dialNumber = number.replace(' ', '-');
        dialLink = <a href={`tel:+852-${dialNumber}`} key={dialNumber}>{number}</a>;
        dialLinkArray.push(dialLink);
        textWithoutNumber = textWithoutNumber.replace(number, '|');
    });
    textArray = textWithoutNumber.split('|').map((text, index) => <span key={`text-${index}`}>{text}</span>);

    let componentArray = [];
    for (let i = 0; i < Math.max(textArray.length, dialLinkArray.length); i++) {
        if (textArray[i]) {
            componentArray.push(textArray[i]);
        }
        if (dialLinkArray[i]) {
            componentArray.push(dialLinkArray[i]);
        }
    }
    return componentArray;
};

//  Screen height and width
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
}


export const getDateStringFromString = (text) => {
    const DateToUse = new Date(text);
    return  DateToUse.toLocaleDateString() + ' ' + DateToUse.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
};

export function calculateArraySum(wieghtArray) {
    let sum = 0; 

    /*loop over array and add each item to sum
    */
    for (const item of wieghtArray) {
      sum += item;
    }
   // return the result 
    return sum;
};

export function addDays(date, daysToAdd) {
    const newDateToUse = new Date(); //today
    newDateToUse.setTime(date.getTime()); //align the time
    newDateToUse.setDate(date.getDate() + daysToAdd);
    return newDateToUse;
};

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
};

