import axios from 'axios'
import {getToken, isLoggedIn} from "./Auth";

const api = axios.create({
    //baseURL: process.env.REACT_APP_BASE_URL, //for server
    baseURL: 'https://web.chorkeematerial.com/api/', //for localhost test 
    timeout: 10000,
    headers: {'Content-Type': 'application/json'},
    responseType: 'json',
    transformRequest: [(data, headers) => {
        if (isLoggedIn()) {
            headers['Authorization'] = `Bearer ${getToken()}`;
        }
        return JSON.stringify(data);
    }]
});

export default api