import React from 'react';
import {
    Button, Card, CardImg, Col, Input, InputGroup, InputGroupAddon, Jumbotron, Modal, ModalFooter, ModalHeader, Row,
} from "reactstrap";
import {getImageType, goToUri, convertFileToBase64} from "../functions/Utility";


function CarMaintenanceDetailReceiptRow(props) {
    const {data, initReceiptImageBase64, onChangeStart, onChangeEnd} = props;

    //  Receipt actions and receipt image displayed
    const hiddenFileInput = React.useRef(null);
    const [showWrongFileType, setShowWrongFileType] = React.useState(false);
    const [receiptImageBase64, setReceiptImageBase64] = React.useState(initReceiptImageBase64);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const onDownloadReceipt = () => {
        const uri = `data:${getImageType(receiptImageBase64).mime};base64,${receiptImageBase64}`;
        const mainDetail = data.mainDetail.replace(/[/\\?%*:|"<>]/g, '-');
        const name = `car-main-receipt-${data.carID}-${mainDetail}.${getImageType(receiptImageBase64).ext}`;
        goToUri(uri, name);
    };
    const onUploadReceipt = () => {
        hiddenFileInput.current.click();
    };
    const onUploadChange = async (event) => {
        console.log(event.target.files);
        console.log(event.target.files[0]);
        const fileUploaded = event.target.files[0];
        if (!fileUploaded) {
            return;
        }
        const fileBase64 = await convertFileToBase64(fileUploaded);
        const fileBase64WithoutPrefix = fileBase64.split(',')[1];
        const imageType = getImageType(fileBase64WithoutPrefix);

        if (imageType && (["jpg", "png", "gif", "webp", "ico"].includes(imageType.ext))) {
            await onChangeStart(fileBase64WithoutPrefix);
            setReceiptImageBase64(fileBase64WithoutPrefix);
            await onChangeEnd(fileBase64WithoutPrefix);
        } else {
            setShowWrongFileType(true);
        }

    };
    const onDeleteReceipt = async () => {
        onChangeStart('');
        setReceiptImageBase64('');
        setIsDeleteModalOpen(false);
        onChangeEnd('');
    };

    return (
        <Row className="mb-2">
            <InputGroup>
                <Input disabled={true} value="收據"/>
                <InputGroupAddon addonType="append">
                    <Button className={!receiptImageBase64 ? "d-none" : ""}
                            color="success" onClick={onDownloadReceipt}>
                        <i className="fas fa-download"/>下載
                    </Button>
                    <Button color="info" onClick={onUploadReceipt}>
                        <i className="fas fa-upload"/>上傳
                    </Button>
                    <Button className={!receiptImageBase64 ? "d-none" : ""}
                            color="danger" onClick={() => setIsDeleteModalOpen(true)}>
                        <i className="fas fa-trash"/>刪除
                    </Button>
                    <Modal isOpen={isDeleteModalOpen} toggle={() => setIsDeleteModalOpen(false)}>
                        <ModalHeader toggle={() => setIsDeleteModalOpen(false)}>確認刪除？此操作無法復原！</ModalHeader>
                        <ModalFooter className="justify-content-between">
                            <Button color="danger" onClick={onDeleteReceipt}>刪除</Button>{' '}
                            <Button color="secondary" onClick={() => setIsDeleteModalOpen(false)}>取消</Button>
                        </ModalFooter>
                    </Modal>
                    <input className="d-none"
                           type="file" name="file"
                           ref={hiddenFileInput}
                           onChange={onUploadChange}/>
                </InputGroupAddon>
            </InputGroup>
            {
                receiptImageBase64 ? (
                    <Card style={{border: "none"}}>
                        <CardImg bottom width="100%"
                                 src={`data:${getImageType(receiptImageBase64).mime};base64,${receiptImageBase64}`}
                                 alt={`car-main-receipt-${data.carID}-${data.mainDetail}`}/>
                    </Card>
                ) : (
                    <Col xs={12}>
                        <Row>
                            <Jumbotron fluid className="w-100 text-center">
                                <h2><i className="fas fa-file-image"/></h2>
                            </Jumbotron>
                        </Row>
                    </Col>
                )
            }
            <Modal isOpen={showWrongFileType} toggle={() => setShowWrongFileType(false)}>
                <ModalHeader toggle={() => setShowWrongFileType(false)}>
                    不是圖片檔案格式！支持以下格式：jpeg, gif, png, webp, ico
                </ModalHeader>
                <ModalFooter className="justify-content-between">
                    <Button color="secondary" onClick={() => setShowWrongFileType(false)}>返回</Button>
                </ModalFooter>
            </Modal>
        </Row>
    );
}

export default CarMaintenanceDetailReceiptRow
