import React from 'react';
import * as Sentry from "@sentry/react";
import {BrowserRouter as Router, Redirect, Route, Switch,} from "react-router-dom";
import BillRecordScreen from "./screens/BillRecordScreen";
import CarMaintenanceScreen from './screens/CarMaintenanceScreen';
import LoginScreen from './screens/LoginScreen';
import GoodSearchScreen from './screens/GoodSearchScreen';
import DeliveryRecordScreen from './screens/DeliveryRecordScreen';
import Invoice80mmPictureGalleryScreen from './screens/Invoice80mmPictureGalleryScreen';
import SalesStatScreen from './screens/SalesStatScreen';
import BonusStatScreen from './screens/BonusStatScreen';
import AppPage from './AppPage';

import './App.css'
import {getAllUsers, isLoggedIn} from "./functions/Auth";
import ErrorScreen from "./screens/ErrorScreen";
import WikiScreen from "./screens/WikiScreen";


function App() {
    //  Update user list
    React.useEffect(() => {
        const initData = async () => {
            await getAllUsers();
        };
        initData().then();
    }, []);
    //  Rendering
    return (
        <Sentry.ErrorBoundary fallback={ErrorScreen}>
            <Router>
                <Switch>
                    <Route path={AppPage.billRecord.route}>
                        <BillRecordScreen/>
                    </Route>
                    <Route path={AppPage.deliveryRecord.route}>
                        <DeliveryRecordScreen/>
                    </Route>
                    <Route path={AppPage.carMaintenance.route}>
                        <CarMaintenanceScreen/>
                    </Route>
                    <Route path={AppPage.goodSearch.route}>
                        <GoodSearchScreen/>
                    </Route>
                    <Route path={AppPage.salesStat.route}>
                        <SalesStatScreen/>
                    </Route>
                    <Route path={AppPage.bonusStat.route}>
                        <BonusStatScreen/>
                    </Route>
                    <Route path={AppPage.invoice80mmPictureGallery.route}>
                        <Invoice80mmPictureGalleryScreen/>
                    </Route>
                    <Route path={AppPage.wiki.routeWithParam} component={WikiScreen} />
                    <Route path={AppPage.wiki.route}>
                        <WikiScreen/>
                    </Route>
                    <Route path={AppPage.login.route}>
                        <LoginScreen/>
                    </Route>
                    <Route path={AppPage.home.route}>
                        {
                            isLoggedIn() ?
                                <Redirect to={AppPage.deliveryRecord.route}/> :
                                <Redirect to={AppPage.login.route}/>
                        }
                    </Route>
                    <Route path={AppPage.root.route}>
                        {
                            isLoggedIn() ?
                                <Redirect to={AppPage.deliveryRecord.route}/> :
                                <Redirect to={AppPage.login.route}/>
                        }
                    </Route>
                </Switch>
            </Router>
        </Sentry.ErrorBoundary>
    )
}

export default App;
