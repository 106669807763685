import api from "./Api";

export const filterGoods = (goodsData, search) => {
    let searchLowerCase = search.toLowerCase();
    return goodsData.filter(data => {
        let goodDescriptionLowerCase = data.goodDescription ? data.goodDescription.toLowerCase() : "";
        let goodDescriptionTrimmed = goodDescriptionLowerCase.replace(/\-/g, "");
        return (
            (data.goodType && data.goodType.toLowerCase().includes(searchLowerCase)) ||
            (data.goodSubType && data.goodSubType.toLowerCase().includes(searchLowerCase)) ||
            (goodDescriptionLowerCase.includes(searchLowerCase)) ||
            (goodDescriptionTrimmed.includes(searchLowerCase)) ||
            (data.goodRemark && data.goodRemark.toLowerCase().includes(searchLowerCase))
        );
    });
};

export const getAllGoodsRecords = async () => {
    const response = await api.get('/goods');
    return response.data;
};
export const getGoodsRecord = async (goodID) => {
    const response = await api.get(`/goods/${goodID}`);
    return response.data;
};

export const updateGoodsRecords = async (goodID, updateData) => {
    const response = await api.put(`/goods/${goodID}`, updateData);
    return response.data;
};

