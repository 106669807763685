import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {getUserById} from "../functions/Auth";
import {getDeliveryStatusColor} from "../functions/Delivery";
import {getBillStatusColor, isPaid} from "../functions/Bill";
import {calculateArraySum} from "../functions/Utility";




const useStyles = makeStyles({
    card: {
        display: 'flex',
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginTop: 16,
    },
    cover: {
        minWidth: 120,
        flex: '0.4 0 auto',
    },
    time: {
        fontSize: 28,
    },
    address: {
        color: 'red'
    },
});

function DeliveryRecordCard(props) {
    const classes = useStyles();
    const data = props.data;
    const proposedDate = new Date(data.proposedDateTime);
    const driver = data ? getUserById(data.driverID) : {rights: "", nickName: ""};
    const statusColor = getDeliveryStatusColor(data.deliveryStatus);
    const billStatusColor = getBillStatusColor(data.billStatus);
    const deliveryWeight = calculateArraySum(data.goods.map((good) => (good.goodDefaultWeight * good.quantity)));

    return (
        <div className="mt-3">
            <Card className={classes.card}>
                <CardContent className="col-8">
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        <strong className="bg-warning p-1 text-dark">{data.carID}</strong>{' '}
                        <strong className={"bg-"+(driver ? driver.rights : "")+" p-1 text-dark"}>{driver ? driver.nickName : ""}</strong>{' '}
                    </Typography>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        <strong className={"bg-"+statusColor+" p-1 text-dark"}>{data.deliveryStatus}</strong>{' '}
                        <strong className={"bg-"+billStatusColor+" p-1 text-dark"}>{data.billStatus}</strong>{' '}
                        {isPaid(data.billStatus) === true ? (
                                    <>
                                        <strong className={"bg-success p-1 text-dark"}>{data.paymentMethod}</strong>
                                    </>
                                ) : ""}
                    </Typography>
                    <Typography variant="h5" component="h2" >
                        {data.address}
                    </Typography>

                    {data.customerName === "" ? (
                        <>
                            <Typography variant="body2" component="h2" >
                                {data.billRemark}
                            </Typography>
                        </>
                        ) : 
                        <>
                            <Typography variant="body2" component="h2" >
                                {data.customerName}
                            </Typography>
                        </>
                    }  
                    <Typography className={classes.pos} color="textSecondary">
                        {data.goodsDescription}
                    </Typography>
                    
                    <Typography color="textSecondary">
                        {Number(deliveryWeight.toFixed(1))}kg{( deliveryWeight >= 1000 ? " （" +  Number((deliveryWeight / 1000).toFixed(2))  + "噸）" : "")}
                    </Typography>
                </CardContent>
                <CardContent className="col-4">
                    <Typography className={classes.time}>
                        {proposedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                        {proposedDate.getFullYear()}年
                    </Typography>
                    <Typography variant="h6" component="h2">
                        {proposedDate.getMonth() + 1}月{proposedDate.getDate()}日
                    </Typography>
                </CardContent>
            </Card>
        </div>
    );
}

export default DeliveryRecordCard
