import { truncate } from "@sentry/utils";
import api from "./Api";

export const filterBillRecords = (deliveryData, search, startDate, endDate, statusList) => {
    return deliveryData.filter(data => {
        let matched;
        let recordDate = new Date(data.recordDateTime).setHours(0, 0, 0, 0);

        matched = (
            //  Date Match
            startDate <= recordDate && recordDate <= endDate
        ) && (
            //  Status Match
            (data.billStatus === '未付' && statusList.notPaid) ||
            (data.billStatus === '已付' && statusList.paid)
        );
        if (matched === false) return matched;

        //  Search string filter
        if ((data.customerName && data.customerName.includes(search)) ||
            (data.remark && data.remark.includes(search)) ||
            (data.paymentID && data.paymentID.includes(search))
        ) {
            return true;
        }
        return false;
    });
};

export const sortBillRecords = (deliveryData, columnName, order) => {
    if (columnName === '') return deliveryData;

    let sortedData = [...deliveryData]; //  Copy data to another array
    sortedData.sort((a, b) => {
        let compare;
        if (columnName === "recordDateTime") {
            compare = (Date.parse(a[columnName]) > Date.parse(b[columnName])) ? 1 : -1;
        } else if (columnName === "netPrice") {
            compare = (parseFloat(a[columnName]) > parseFloat(b[columnName])) ? 1 : -1;
        }
        return compare * order; //  order: 1=ascending, -1=descending
    });
    return sortedData;
};

export const getAllBillRecords = async () => {
    const response = await api.get('/billRecords');
    return response.data;
};

export const getAllBillRecordsWithDateRange = async (startDate, endDate) => {

    const startDateFormat = new Date(startDate).toLocaleDateString('en-US');
    const endDateFormat = new Date(endDate).toLocaleDateString('en-US');
    const response = await api.get(`/billRecords?start=${startDateFormat}&end=${endDateFormat}`);
    return response.data;
};

export const getBillRecordsFast = async () => {
    const response = await api.get('/billRecords/fast');
    return response.data;
};
export const getBillRecordById = async (paymentID) => {
    const response = await api.get(`/billRecords/${paymentID}`);
    return response.data;
};

export const getBillStatusColor = (status) => {
    if (status === "已付") {
        return 'success';
    } else if (status === "未付") {
        return 'danger';
    }
    return '';
};

export const isPaid = (status) => {
    if (status === "已付") {
        return true;
    } else {
        return false;
    };
};

export const getBillPaymentImage = async (paymentID) => {
    const response = await api.get(`/billRecords/${paymentID}/paymentImage`);
    return response.data;
};

export const getBillWhatsappString = (paymentID, invoiceUrl, customerName, billRemark) => {
    let remarkString = "";
    if (customerName === "") {
        remarkString = billRemark;
    } else {
        remarkString = customerName;
    };
    return "單號：" + paymentID + "（" + remarkString + "）"
    + "<br />" + "<br />" 
    + "按此連結：" + invoiceUrl 
    + "<br />" + "<br />" 
    + "如單據有更改,連結會自動更新"
    + "<br />" 
    + "有問題請whatsapp查詢,謝謝";
};

export const getBillDevileryWhatsappString = (paymentID, invoiceUrl, customerName, billRemark, deliveryAddress) => {
    let remarkString = "";
    if (customerName === "") {
        remarkString = billRemark;
    } else {
        remarkString = customerName;
    };
    return "單號：" + paymentID + "（" + remarkString + "）"
    + "\n" 
    + "送貨地址：" + deliveryAddress
    + "\n" + "\n" 
    + "按此連結：" + invoiceUrl 
    + "\n" + "\n" 
    + "如單據有更改,連結會自動更新"
    + "\n" 
    + "有問題請whatsapp查詢,謝謝";
};