import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {getCarMaintenanceStatusColor} from "../functions/Car";
import {getRights} from "../functions/Auth";

const useStyles = makeStyles({
    card: {
        display: 'flex',
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    cover: {
        minWidth: 120,
        flex: '0.4 0 auto',
    }
});

function CarMaintenanceCard(props) {
    const classes = useStyles();
    const data = props.data;
    const mainDate = new Date(data.mainDate);
    const statusColor = getCarMaintenanceStatusColor(data.mainStatus);

    //  Initial API call and display
    React.useEffect(() => {
    }, []);

    return (
        <div className="mt-3">
            <Card className={classes.card}>
                <CardContent className="col-8">
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        <strong className="bg-warning p-1 text-dark">{data.carID}</strong>{' '}
                        <strong className={"bg-"+statusColor+" p-1 text-dark"}>{data.mainStatus}</strong>{' '}
                        {data.mainType}
                    </Typography>
                    <Typography variant="h5" component="h2">
                        {data.mainDetail}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                        $ {parseFloat(data.mainFee).toFixed(2)}
                    </Typography>
                </CardContent>
                <CardContent className="col-4">
                    <Typography className={classes.pos} color="textSecondary">
                        {mainDate.getFullYear()}年
                    </Typography>
                    <Typography variant="h5" component="h2">
                        {mainDate.getMonth() + 1}月 {mainDate.getDate()}日
                    </Typography>
                </CardContent>
            </Card>
        </div>
    );
}

export default CarMaintenanceCard
