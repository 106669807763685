import * as Sentry from "@sentry/react";
import api from './Api'

export const getAllUsers = async () => {
    let response = await api.get('/auth/users');
    let userList = response.data;
    localStorage.setItem("userList", JSON.stringify(userList));
    return userList;
  
};

export const getAllUsersCache = () => {
    //  Get cached list from local storage
    let userList = localStorage.getItem("userList");
    if (userList !== null) return JSON.parse(userList);
    return [];
};

export const getAllUsersObjectCache = () => {
    //  Get cached list from local storage
    let userList = localStorage.getItem("userList");
    let userListObject = {};
    if (userList !== null) {
        userList = JSON.parse(userList);
        userList.forEach((user) => {
            userListObject[user.userId] = user;
        });
    }
    return userListObject;
};

export const login = async (username, password) => {
    const response = await api.post('/auth/login', {userId: username, password: password});

    if (response.status === 200) {
        const user = response.data;
        localStorage.setItem("token", user.token);
        localStorage.setItem("userId", user.userId);
        localStorage.setItem("nickName", user.nickName);
        localStorage.setItem("rights", user.rights);

        let loginHistory = JSON.parse(localStorage.getItem("loginHistory")) || {};
        loginHistory[user.userId] = (new Date()).getTime().toString();
        localStorage.setItem("loginHistory", JSON.stringify(loginHistory));

        //  Sentry set user
        Sentry.setUser({
            id: user.userId,
            username: user.nickName,
        });
        return true;
    } else {
        return false;
    }
};

export const isLoggedIn = () => {
    return localStorage.getItem("token") !== null;
};

export const getToken = () => {
    return localStorage.getItem("token");
};

export const getUserId = () => {
    return localStorage.getItem("userId");
};

export const getNickname = () => {
    return localStorage.getItem("nickName");
};

export const getRights = () => {
    return localStorage.getItem("rights");
};

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('rights');
    localStorage.removeItem('userId');
    localStorage.removeItem('userList');
    //  Sentry set no user
    Sentry.configureScope(scope => scope.setUser(null));
};

export const getLastLoginList = async () => {
    let loginHistory = JSON.parse(localStorage.getItem("loginHistory")) || {};
    let lastLoginList = [];
    let userList = await getAllUsers();

    for (const [loginUserId, loginTime] of Object.entries(loginHistory)) {
        let lastLoginUser = userList.find(user => user.userId === loginUserId);
        lastLoginList.push({
            userId: loginUserId,
            rights: lastLoginUser.rights,
            nickName: lastLoginUser.nickName,
            loginTime: loginTime,
        });
    }
    //  Sort by login time: latest first
    lastLoginList.sort((a, b) => {
        if (a.loginTime > b.loginTime) return -1;
        if (a.loginTime < b.loginTime) return 1;
        return 0;
    });
    return lastLoginList;
};

export const getUserById = (userId) => {
    let userList = getAllUsersCache();
    return userList.find(user => user.userId === userId);
};
