import api from "./Api";

export const getAllSalesStat = async () => {
    // format: 2/23/2023, MM/DD/YYYY 
    //   let startDateString = startDate.toLocaleDateString('en-us', { year: 'numeric', month: 'numeric', day: 'numeric'});
    //   let endDateString = endDate.toLocaleDateString('en-us', { year: 'numeric', month: 'numeric', day: 'numeric'});
    const response = await api.get(`/stat/getDailySalesVolume`);
    return response.data;
};



//  Layout data handling functions
export const filterSalesStat = (salesStatData, startDate, endDate) => {

    return salesStatData.filter(data => {
        let matched;
        let salesDate = new Date(data.date.replace(/-/g, "/")); //ios doesnt support YYYY-mm-dd

        matched = (
            //  Date Match
            startDate.setHours(0, 0, 0) <= salesDate.setHours(0, 0, 1) && salesDate.setHours(0, 0, 0) <= endDate.setHours(0, 0, 0)
        );
        return matched;
    });
};


export const sortSalesStat = (salesStatData, columnName, order) => {
    if (columnName === '') return salesStatData;

    let sortedData = [...salesStatData]; //  Copy data to another array
    sortedData.sort((a, b) => {
        let compare;
        if (columnName === "date") {
            compare = (Date.parse(a[columnName]) > Date.parse(b[columnName])) ? 1 : -1;
        } else if (columnName === "salesVolume") {
            compare = (parseFloat(a[columnName]) > parseFloat(b[columnName])) ? 1 : -1;
        } else if (columnName === "deliveryCount") {
            compare = (parseFloat(a[columnName]) > parseFloat(b[columnName])) ? 1 : -1;
        }
        return compare * order; //  order: 1=ascending, -1=descending
    });
    return sortedData;
};

