import React from 'react';
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import useWindowDimensions from "../functions/Utility";



function GeneralConfirmModal(props) {
    const {isOpen, toggle, onSubmit, headerText} = props;
    const {width} = useWindowDimensions();

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                {headerText}
            </ModalHeader>
            <ModalBody>
                <Button color="info" className="mr-2 d-inline" onClick={onSubmit} >確定</Button>
                
                <Button  color="danger"className="mr-2 d-inline" onClick={toggle}>取消</Button>
        
            </ModalBody>
        </Modal>
    );
}

export default GeneralConfirmModal
