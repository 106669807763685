import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {getBillStatusColor} from "../functions/Bill";

const useStyles = makeStyles({
    card: {
        display: 'flex',
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    cover: {
        minWidth: 120,
        flex: '0.4 0 auto',
    }
});

function BillRecordCard(props) {
    const classes = useStyles();
    const data = props.data;
    const paymentDate = new Date(data.paymentDateTime);
    const statusColor = getBillStatusColor(data.billStatus);

    return (
        <div className="mt-3">
            <Card className={classes.card}>
                <CardContent className="col-8">
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        <strong className={"bg-"+statusColor+" p-1 text-dark"}>{data.billStatus}</strong>{' '}
                        <strong className={"bg-warning p-1 text-dark"}>{data.paymentMethod}</strong>
                        {data.paymentImageCount > 0 ?
                            <strong className={"pl-2 text-success"}>
                                <i className="fas fa-file-invoice-dollar"/>
                            </strong> : ""}
                    </Typography>
                    <Typography variant="h5" component="h2">
                        {data.customerName}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                        $ {data.netPrice.toFixed(2)}
                    </Typography>
                </CardContent>
                <CardContent className="col-4">
                    <Typography className={classes.pos} color="textSecondary">
                        {paymentDate.getFullYear()}年
                    </Typography>
                    <Typography variant="h5" component="h2">
                        {paymentDate.getMonth() + 1}月 {paymentDate.getDate()}日
                    </Typography>
                </CardContent>
            </Card>
        </div>
    );
}

export default BillRecordCard
