import React, { useRef } from 'react';
import Typography from '@material-ui/core/Typography';
import {
    Button,
    ButtonGroup,
    Col,
    Container,
    Dropdown, DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Input, InputGroup, InputGroupAddon, InputGroupText,
} from "reactstrap";
import DatePicker from "react-datepicker";
import { sort } from "fast-sort";
import QRCode from "qrcode";
import { getRights, getUserById } from "../functions/Auth";
import {
    addDeliverySignature, changeDeliveryCarID,
    changeDeliveryLocation,
    changeDeliveryStatus, changeDriverID, getAllDriversCache, getDeliverySignature,
    getDeliveryStatusColor,
    splitDeliveryRemark,
    changeDeliveryProposedDatetime,
    getDeliveryPhoto,
    addDeliveryPhoto,
    deleteDeliveryPhoto,
    changeDeliveryRemark
} from "../functions/Delivery";
import SpinnerOverlay from "./components/SpinnerOverlay";
import { isEmpty, sleep, addPhoneLink, getImageType, calculateArraySum } from "../functions/Utility";
import DeliveryRecordGoogleMapModal from "./DeliveryRecordGoogleMapModal";
import DeliveryRecordSignatureModal from "./DeliveryRecordSignatureModal";
import DeliveryRecordUpdateDeliveryRemarkModal from "./DeliveryRecordUpdateDeliveryRemarkModal";
import GeneralConfirmModal from "./GeneralConfirmModal";
import BillRecordDetailModal from "./BillRecordDetailModal";
import { getBillRecordById } from "../functions/Bill";
import { getAllCarsFromCache } from "../functions/Car";
import { getBillStatusColor, isPaid, getBillDevileryWhatsappString } from "../functions/Bill";
import { getDateStringFromString, convertFileToBase64, resizeFile } from "../functions/Utility";
import { saveAs } from 'file-saver';

const fs = require('fs');

function DeliveryRecordDetailModal(props) {
    const { isOpen, toggle, data } = props;
    const driver = data && !isEmpty(data) ? getUserById(data.driverID) : {};

    const [isLoading, setIsLoading] = React.useState(false);
    //  Driver Dropdown toggle
    const [driverDropdownOpen, setDriverDropdownOpen] = React.useState(false);
    const driverDropdownToggle = () => setDriverDropdownOpen(prevState => !prevState);
    const onDriverDropdownSelect = async (driverID) => {
        setIsLoading(true);
        await changeDriverID(data.deliveryID, driverID);
        await sleep(500);
        data.driverID = driverID;
        setIsLoading(false);
    };
    //  Car ID Dropdown toggle
    const [carIdDropdownOpen, setCarIdDropdownOpen] = React.useState(false);
    const carIdDropdownToggle = () => setCarIdDropdownOpen(prevState => !prevState);
    const onCarIdDropdownSelect = async (carID) => {
        setIsLoading(true);
        await changeDeliveryCarID(data.deliveryID, carID);
        await sleep(500);
        data.carID = carID;
        setIsLoading(false);
    };
    //  Select delivery status
    const onButtonSelect = async (status) => {
        setIsLoading(true);
        await changeDeliveryStatus(data.deliveryID, status);
        await sleep(500);
        data.deliveryStatus = status;
        setIsLoading(false);    //  Render update the loading state and changed delivery status
    };
    //  QR code
    const [invoiceUrlQrCode, setInvoiceUrlQrCode] = React.useState("");
    const [deliveryNoteUrlQrCode, setDeliveryNoteUrlQrCode] = React.useState("");
    //  Google Map
    const [isGoogleMapOpen, setIsGoogleMapOpen] = React.useState(false);
    const onLocationSelected = async (position) => {
        setIsLoading(true);
        await changeDeliveryLocation(data.deliveryID, position);
        Object.assign(data, {
            address_GPS_latitude: position.latitude,
            address_GPS_longtitude: position.longitude
        });
        setIsLoading(false);
    };
    //  Signature
    const [signatureIsOpen, setSignatureIsOpen] = React.useState(false);
    const [signature, setSignature] = React.useState("");

    //  Bill detail
    const [billDetailIsOpen, setBillDetailIsOpen] = React.useState(false);
    const [billData, setBillData] = React.useState(null);

    //  Date change
    const datePickerRef = useRef(null);
    const [proposedDateTime, setProposedDateTime] = React.useState(new Date());   //  State of date picker
    const onProposedDateTimeChange = async (date) => {
        setIsLoading(true);
        await changeDeliveryProposedDatetime(data.deliveryID, date);
        await sleep(500);
        setIsLoading(false);
    };

    // whatsapp text to customer
    const [whatsappTextToCustomer, setWhatsappTextToCustomer] = React.useState("");

    // copy button
    const [copyButtonFlag, setCopyButtonFlag] = React.useState(false);

    // change date picker
    const [isDateValueChanged, setIsDateValueChanged] = React.useState(false);

    // image to upload 
    // const [imageFileToUpload, setImageFileToUpload] = React.useState();

    // Delivery Photo from dB
    const [deliveryPhotoBase64, setDeliveryPhotoBase64] = React.useState('');
    const [deleteDeliveryPhotoModalIsOpen, setDeleteDeliveryPhotoModalIsOpen] = React.useState(false);


    //  Update Delivery Remark 
    const [updateDeliveryRemarkIsOpen, setUpdateDeliveryRemarkIsOpen] = React.useState(false);
    const [deliveryRemarkST, setDeliveryRemarkST] = React.useState("");

    // upload delivery photo
    const inputUploadExistingPhoto = useRef(null);
    const uploadImage = async (imageToUpload) => {
        setIsLoading(true);
        //resize file will auto encoded to base 64
        let imageToUploadResized = await resizeFile(imageToUpload);
        let imageBase64 = imageToUploadResized;
        let imageBase64HeaderRemoved = imageBase64.split(',')[1];
        // remove the header from the image
        await addDeliveryPhoto(data.deliveryID, imageBase64HeaderRemoved);
        setDeliveryPhotoBase64(imageBase64HeaderRemoved);
        setIsLoading(false);
    };


    // delete delivery photo
    const deleteDeliveryPhotoCmd = async (deliveryID) => {
        setIsLoading(true);
        await deleteDeliveryPhoto(deliveryID);
        setDeliveryPhotoBase64('');
        setIsLoading(false);
    };

    // Weight of this delivery
    const [deliveryWeight, setDeliveryWeight] = React.useState(0);
 

    React.useEffect(() => {
        if (!data || isEmpty(data)) return;
        //  Initial API call
        const initData = async () => {
            let signatureData, invoiceQrCode, deliveryNoteQrCode;
            let deliveryPhotoData;
            setIsLoading(true);
            // signature
            signatureData = await getDeliverySignature(data.deliveryID);
            setSignature(signatureData.ImageBase64Content);

            // invoice QR code
            if (data.invoiceUrl) {
                invoiceQrCode = await QRCode.toDataURL(data.invoiceUrl);
                setInvoiceUrlQrCode(invoiceQrCode);
            }
            if (data.deliveryNoteUrl) {
                deliveryNoteQrCode = await QRCode.toDataURL(data.deliveryNoteUrl);
                setDeliveryNoteUrlQrCode(deliveryNoteQrCode)
            }
            // time picker: proposedDateTime
            setProposedDateTime(new Date(data.proposedDateTime));

            // deliveryRemark
            setDeliveryRemarkST(data.deliveryRemark);

            // deliveryPhoto from backend
            deliveryPhotoData = await getDeliveryPhoto(data.deliveryID);
            setDeliveryPhotoBase64(deliveryPhotoData.ImageBase64Content);

            setWhatsappTextToCustomer(getBillDevileryWhatsappString(
                data.paymentID
                , data.invoiceUrl
                , data.customerName
                , data.billRemark
                , data.address));

            
            //weight 
            setDeliveryWeight(
                calculateArraySum(data.goods.map((good) => (good.goodDefaultWeight * good.quantity)))
                );

            setIsLoading(false);
        };
        initData().then();
    }, [data]);

    return data ? (
        <Modal isOpen={isOpen} toggle={toggle}>
            {isLoading ? <SpinnerOverlay /> : ""}
            <ModalHeader toggle={toggle}>
                <div className="pt-2 pb-2">
                    {addPhoneLink(data.address)}
                    <Button className="btn-light ml-1" onClick={() => setIsGoogleMapOpen(true)}>
                        <i className="fas fa-map-marker-alt text-danger" />
                    </Button>
                    <DeliveryRecordGoogleMapModal isOpen={isGoogleMapOpen}
                        toggle={() => setIsGoogleMapOpen(false)}
                        address={data.address}
                        initLatitude={data.address_GPS_latitude}
                        initLongitude={data.address_GPS_longtitude}
                        onLocationSelected={onLocationSelected} />
                </div>
                <Typography color="textSecondary">
                    <strong className="bg-warning p-1 text-dark">{data.carID}</strong>{' '}
                    <strong className={"bg-" + (driver ? driver.rights : "") + " p-1 text-dark"}>{driver ? driver.nickName : ""}</strong>{' '}
                    <strong className={"bg-" + getDeliveryStatusColor(data.deliveryStatus) + " p-1 text-dark"}>{data.deliveryStatus}</strong>{' '}
                    <strong className={"bg-" + getBillStatusColor(data.billStatus) + " p-1 text-dark"}>{data.billStatus}</strong>{' '}
                    {isPaid(data.billStatus) === true ? (
                        <>
                            <strong className={"bg-success p-1 text-dark"}>{data.paymentMethod}</strong>
                        </>
                    ) : ""}
                </Typography>
                <Typography color="textSecondary">
                    <strong>{data.deliveryID}{' - '}{getDateStringFromString(data.proposedDateTime)}</strong>
                </Typography>

                {deliveryRemarkST === "" ? (
                    <>
                        <Typography color="textSecondary">
                            <strong>{addPhoneLink(data.billRemark)}</strong>
                        </Typography>
                    </>
                ) :
                    <>
                        {splitDeliveryRemark(deliveryRemarkST).map((remark, index) => (
                            <Typography key={`${data.deliveryID}-remark-${index}`} className="pl-2 pt-2 m-0 text-danger" color="textSecondary">
                                <strong>{addPhoneLink(remark)}</strong>
                            </Typography>
                        ))}

                    </>}
            </ModalHeader>
            <ModalBody>


                <ButtonGroup className="mb-2 w-100">
                    <Button color={data.deliveryStatus === '排程中' ? "info" : "secondary"}
                        style={data.deliveryStatus !== '排程中' ? { opacity: 0.5 } : {}}
                        outline={data.deliveryStatus !== '排程中'}
                        onClick={() => onButtonSelect('排程中')}>
                        排程中
                    </Button>
                    <Button color={data.deliveryStatus === '準備好' ? "danger" : "secondary"}
                        style={data.deliveryStatus !== '準備好' ? { opacity: 0.5 } : {}}
                        outline={data.deliveryStatus !== '準備好'}
                        onClick={() => onButtonSelect('準備好')}>
                        準備好
                    </Button>
                    <Button color={data.deliveryStatus === '送緊貨' ? "warning" : "secondary"}
                        style={data.deliveryStatus !== '送緊貨' ? { opacity: 0.5 } : {}}
                        outline={data.deliveryStatus !== '送緊貨'}
                        onClick={() => onButtonSelect('送緊貨')}>
                        送緊貨
                    </Button>
                    <Button color={data.deliveryStatus === '完成' ? "success" : "secondary"}
                        style={data.deliveryStatus !== '完成' ? { opacity: 0.5 } : {}}
                        outline={data.deliveryStatus !== '完成'}
                        onClick={() => onButtonSelect('完成')}>
                        完成
                    </Button>
                </ButtonGroup>



                <Container >
                    <Row className="mb-2">
                        {getRights() !== "driver" ? (
                            <>
                                <Dropdown className="mr-2 d-inline" isOpen={carIdDropdownOpen} toggle={carIdDropdownToggle}>
                                    <DropdownToggle caret color="warning">
                                        更改車牌
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {getAllCarsFromCache().map((car) => {
                                            return <DropdownItem onClick={() => onCarIdDropdownSelect(car.carID)}>{car.carID}</DropdownItem>;
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                            </>
                        ) : ""}

                        {getRights() !== "driver" ? (
                            <>
                                <Dropdown className="mr-2" isOpen={driverDropdownOpen} toggle={driverDropdownToggle}>
                                    <DropdownToggle caret color="primary">
                                        更改司機
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {getAllDriversCache().map((user) => {
                                            return <DropdownItem onClick={() => onDriverDropdownSelect(user.driver_userId)}>{user.driver_nickName}</DropdownItem>;
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                            </>
                        ) : ""}

                        {getRights() !== "driver" ? (
                            <>
                                <Button className="mr-2" color="info" onClick={() => setUpdateDeliveryRemarkIsOpen(true)}>收貨資料</Button>

                                <DeliveryRecordUpdateDeliveryRemarkModal isOpen={updateDeliveryRemarkIsOpen}
                                    toggle={() => setUpdateDeliveryRemarkIsOpen(false)}
                                    originalDeliveryRemark={deliveryRemarkST}
                                    onSubmit={async (deliveryRemarkText) => {
                                        setIsLoading(true);
                                        await changeDeliveryRemark(data.deliveryID, deliveryRemarkText);
                                        setDeliveryRemarkST(deliveryRemarkText);
                                        setUpdateDeliveryRemarkIsOpen(false);
                                        setIsLoading(false);
                                    }} />
                            </>
                        ) : ""}
                    </Row>


                    <Row className="mb-2">
                        {getRights() !== "driver" ? (
                            <>
                                <InputGroup className="mr-2" >
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText  ><i className="fas fa-clock fa-1x"/></InputGroupText>
                                    </InputGroupAddon>

                                    <DatePicker selected={proposedDateTime}
                                        onChange={(date) => {
                                            setProposedDateTime(date);
                                            setIsDateValueChanged(true); //tuen on the tick button
                                        }}
                                        showTimeSelect //allow time input by select
                                        // withPortal //pop up a new screen to do the selection
                                        timeFormat="HH:mm"
                                        timeCaption="時間"
                                        dateFormat="yyyy-MM-dd EEE HH:mm" />

                                    {(isDateValueChanged === true) ?
                                        <InputGroupAddon addonType="append">
                                            <Button color="success" onClick={async () => {
                                                //  await onDataUpdate({inventory: inventory});
                                                await onProposedDateTimeChange(proposedDateTime);
                                                setIsDateValueChanged(false); //turn off this button
                                            }}>
                                                <i className="fas fa-check" />
                                            </Button>
                                        </InputGroupAddon> : ""
                                    }



                                </InputGroup>
                            </>
                        ) : ""}
                    </Row>
                </Container>

                <Container style={{
                    color: "black",
                    border: "1px solid rgba(0,0,0,.125)",
                    borderRadius: ".25rem",
                }}>
                    <Row className="bg-secondary pt-2 pb-2">
                        <Col xs={8}>項目內容</Col>
                        <Col xs={2} className="p-0">數目</Col>
                        <Col xs={2} className="p-0">單位</Col>
                    </Row>
                    {data.goods.map((good) => (
                        <Row key={`${data.deliveryID}-good-${good.goodID}`} className="pt-2 pb-2">
                            <Col xs={8}>
                                <div>{good.goodDescription}</div>
                                {good.remarks !== "" ? (
                                    <div className="pl-2 text-danger">{good.remarks}</div>
                                ) : ""}
                            </Col>
                            <Col xs={2} className="p-0">{good.quantity}</Col>
                            <Col xs={2} className="p-0">{good.goodUnit}</Col>
                        </Row>
                    ))}
                    <Row className="bg-secondary pt-2 pb-2">
                        <Col xs={8}>總重</Col>
                        <Col xs={2} className="p-0">{Number(deliveryWeight.toFixed(1))}</Col>
                        <Col xs={2} className="p-0">kg</Col>
                    </Row>
                    
                    {deliveryWeight >= 1000 ? (
                        
                        <Row className="bg-secondary pt-2 pb-2">
                            <Col xs={8}></Col>
                            <Col xs={2} className="p-0">{Number((deliveryWeight / 1000).toFixed(2))}</Col>
                            <Col xs={2} className="p-0">噸</Col>
                        </Row>
                    ): ""}

                </Container>
                <Container>
                    <Row className="m-2 justify-content-center">
                        <a target="_blank" rel="noreferrer" href={data.deliveryNoteUrl}>
                            <img src={deliveryNoteUrlQrCode} alt="invoice-qr-code" />
                        </a>
                    </Row>
                </Container>
                <div className="mt-3 mb-3 p-3 d-flex justify-content-between"
                    style={{
                        backgroundImage: signature ? `url(data:image/${getImageType(signature).ext};base64,${signature})` : "none",
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        border: "3px dotted lightgray",
                        color: "gray",
                        height: "200px"
                    }}>
                    <strong>簽名</strong>
                    <Button className="align-self-end" color="info" size="lg" onClick={() => setSignatureIsOpen(true)}>
                        <i className="fas fa-signature" />
                        <i className="fas fa-pen-fancy" />
                    </Button>
                </div>
                <DeliveryRecordSignatureModal isOpen={signatureIsOpen}
                    toggle={() => setSignatureIsOpen(false)}
                    onSubmit={async (dataUrl) => {
                        let imageBase64 = dataUrl.split(',')[1];
                        setIsLoading(true);
                        await addDeliverySignature(data.deliveryID, imageBase64);
                        setSignature(imageBase64);
                        setSignatureIsOpen(false);
                        setIsLoading(false);
                    }} />

                <div className="mt-3 mb-3 p-3 d-flex justify-content-between"
                    // " d-flex justify-content-between"
                    style={{
                        //   backgroundImage: `url(${imageURLFromDB})`,
                        backgroundImage: deliveryPhotoBase64 ? `url(data:image/${getImageType(deliveryPhotoBase64).ext};base64,${deliveryPhotoBase64})` : "none",
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        border: "3px dotted lightgray",
                        color: "gray",
                        height: "200px"
                    }}>

                    <label for="file-upload-t" className="custom-file-upload"
                        style={{
                            width: '100%'
                        }}>
                    </label>
                    <Input
                        type='file'
                        style={{
                            display: 'none',
                            opacity: '1'
                        }}
                        //  className="d-flex"
                        id="file-upload-t"
                        accept="image/*"
                        capture="environment"
                        onChange={async (e) => {
                            if (e.target.files[0] !== undefined) {
                                // if is undefined, do not do anything
                                await uploadImage(e.target.files[0]);
                            }
                        }}
                    />
                    <Container className="align-self-end" style={{ width: 'fit-content', height: 'fit-content' }}>
                        <Row>
                            <input
                                type='file'
                                ref={inputUploadExistingPhoto}
                                style={{
                                    display: 'none'
                                }}
                                accept="image/*"
                                onChange={async (e) => {
                                    if (e.target.files[0] !== undefined) {
                                        // if is undefined, do not do anything
                                        await uploadImage(e.target.files[0]);
                                    }
                                }}
                            />
                            <Button className="align-self-end m-1" color="warning" size="lg" onClick={() => inputUploadExistingPhoto.current.click()}>
                                <i className="fa fa-upload" />
                            </Button>
                        </Row>
                        <Row>
                            <Button className="align-self-end m-1" color="info" size="lg" onClick={() => {

                                if (deliveryPhotoBase64 === '' || !deliveryPhotoBase64) {
                                    // do not do anything
                                } else {
                                    saveAs(`data:image/${getImageType(deliveryPhotoBase64).ext};base64,${deliveryPhotoBase64}`, `${data.deliveryID}.${getImageType(deliveryPhotoBase64).ext}`);
                                }
                            }}>
                                <i className="fa fa-download" />
                            </Button>
                        </Row>
                        <Row >
                            <Button className="align-self-end m-1" color="danger" size="lg"
                                onClick={async () => {
                                    if (deliveryPhotoBase64 === '' || !deliveryPhotoBase64) {
                                        // do not delete
                                    } else {
                                        setDeleteDeliveryPhotoModalIsOpen(true);
                                    }
                                }}>
                                <i className="fa fa-window-close" />
                            </Button>
                            <GeneralConfirmModal isOpen={deleteDeliveryPhotoModalIsOpen}
                                headerText={'確認刪除？'}
                                toggle={() => setDeleteDeliveryPhotoModalIsOpen(false)}
                                onSubmit={async () => {
                                    await deleteDeliveryPhotoCmd(data.deliveryID);
                                    setDeleteDeliveryPhotoModalIsOpen(false);
                                }} />
                        </Row>
                        <Row>
                        </Row>
                    </Container>



                </div>

                <Typography className="mb-2 text-center" variant="h6" color="textSecondary" gutterBottom>
                    <strong className="text-dark">{data.customerName}</strong>{' '}
                </Typography>
                {data.customerPhoneList.length > 0 ? (
                    <Container style={{
                        color: "black",
                        border: "1px solid rgba(0,0,0,.125)",
                        borderRadius: ".25rem",

                    }}>
                        <Row className="bg-secondary pt-2 pb-2">
                            <Col xs={6}>聯絡人</Col>
                            <Col xs={6} className="p-0">電話</Col>
                        </Row>
                        {sort(data.customerPhoneList).desc(u => u.priority).map((customerPhone, index) => (
                            <Row key={`${data.deliveryID}-customer-phone-${index}`}
                                className="pt-2 pb-2">
                                <Col xs={6}>{customerPhone.phoneUserName}</Col>
                                <Col xs={6} className="p-0">
                                    {addPhoneLink(customerPhone.phoneNumber)}
                                </Col>
                            </Row>
                        ))}
                    </Container>
                ) : ""}
                {data.invoiceUrl ? (
                    <Container>
                        <Row className="m-2 justify-content-center">
                            <a target="_blank" rel="noreferrer" href={data.invoiceUrl}>
                                <img src={invoiceUrlQrCode} alt="invoice-qr-code" />
                            </a>
                        </Row>
                    </Container>
                ) : ""}

                {getRights() !== "driver" ? (
                    <Input style={{
                        height: "260px"
                    }} type="textarea" name="text" id="exampleText" value={whatsappTextToCustomer} />
                ) : ""}

                {getRights() !== "driver" ? (
                    <Container>
                        <Row className="mb-2 justify-content-center">
                            <Button className="d-inline p-3 m-1" size="lg"
                                onClick={async () => {
                                    setCopyButtonFlag(true);
                                    navigator.clipboard.writeText(whatsappTextToCustomer);
                                    await sleep(1000);
                                    setCopyButtonFlag(false);
                                }}
                                color={copyButtonFlag ? "success" : "info"}>

                                <i className="fas fa-file-invoice-dollar" /> 複製文字
                            </Button>
                            <Button className="d-inline p-3 m-1" color="info" size="lg"
                                onClick={async () => {
                                    let billData;
                                    setIsLoading(true);
                                    billData = await getBillRecordById(data.paymentID);
                                    setBillData(billData);
                                    setBillDetailIsOpen(true);
                                    setIsLoading(false);
                                }}>
                                <i className="fa fa-clone" /> 發票資料
                            </Button>
                        </Row>
                    </Container>
                ) : ""}
                <BillRecordDetailModal isOpen={billDetailIsOpen}
                    toggle={() => setBillDetailIsOpen(false)}
                    data={billData} />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={toggle}>OK</Button>
            </ModalFooter>
        </Modal>
    ) : <div />;
}

export default DeliveryRecordDetailModal
