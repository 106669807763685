import React, {useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {updateGoodsRecords} from "../functions/Goods";
import {sleep} from "../functions/Utility";
import {Button, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row} from "reactstrap";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";

const useStyles = makeStyles({
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    cover: {
        minWidth: 120,
        flex: '0.4 0 auto',
    }
});

function GoodSearchCard(props) {
    const classes = useStyles();
    const {data, onDetailClick, onUpdateStart, onUpdateEnd} = props;

    //  Inventory edit
    const inventoryRef = useRef(null);
    const [inventory, setInventory] = React.useState(data.inventory);
    const [inventoryIsError, setInventoryIsError] = React.useState(false);
    //  General data update function
    const onDataUpdate = async (updateData) => {
        onUpdateStart();
        await updateGoodsRecords(data.goodID, updateData);
        await sleep(500);
        Object.assign(data, updateData);
        onUpdateEnd();
    };

    return (
        <div className="mt-3">
            <Card className={classes.card}>
                <CardActionArea onClick={onDetailClick}>
                    <Container>
                        <Row>
                            <CardContent className="col-7">
                                <Typography variant="h5" component="h2">
                                    {data.goodDescription}
                                </Typography>
                                <Typography className={classes.pos} color="textSecondary">
                                    {data.goodType} - {data.goodSubType}
                                </Typography>
                                <Typography className={classes.pos} color="textSecondary">
                                    {data.goodRemark}
                                </Typography>
                            </CardContent>
                            <CardContent className="col-5">
                                <Typography variant="h5" component="h2">
                                    $ {data.goodDefaultUnitPrice} / {data.goodDefaultUnitQuantity}{data.goodUnit}
                                </Typography>
                                <Typography className={classes.pos} color="textSecondary">
                                    {data.goodDefaultWeight} kg / {data.goodDefaultUnitQuantity}{data.goodUnit}
                                </Typography>
                            </CardContent>
                        </Row>
                    </Container>
                </CardActionArea>
                <CardActions>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>還有</InputGroupText>
                        </InputGroupAddon>
                        <Input ref={inventoryRef}
                               style={inventoryIsError ? {color: "#c13637", borderColor: "#c13637"} : {}}
                               type="text"
                               inputMode="decimal"
                               value={inventory}
                               onChange={(e) => {
                                   if (e.target.value === "" || isNaN(e.target.value)) {
                                       setInventoryIsError(true);
                                   } else {
                                       setInventoryIsError(false);
                                   }
                                   setInventory(e.target.value);
                               }}/>
                        {(inventoryRef.current !== null && data.inventory != inventory && inventoryIsError === false) ?
                            <InputGroupAddon addonType="append">
                                <Button color="success" onClick={async () => {
                                    await onDataUpdate({inventory: inventory});
                                }}>
                                    <i className="fas fa-check"/>
                                </Button>
                            </InputGroupAddon> : ""
                        }
                        <InputGroupAddon addonType="append">
                            <InputGroupText>{data.goodUnit}</InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                </CardActions>
            </Card>
        </div>
    );
}

export default GoodSearchCard
