import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
    Button, Card, CardBody, CardImg, Input, InputGroup, InputGroupAddon, InputGroupText,
    Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";
import {getImageType, isEmpty} from "../functions/Utility";
import * as Sentry from "@sentry/react";


function GoodSearchDetailModal(props) {
    const {isOpen, toggle, data} = props;
    const canShare = (typeof data.imageList !== "undefined" && data.imageList.length > 0);

    return !isEmpty(data) ? (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                <div className="pt-2 pb-2">
                    {data.goodDescription}
                </div>
                <Typography color="textSecondary">
                    {data.goodType} - {data.goodSubType}
                </Typography>
            </ModalHeader>
            <ModalBody>
                <Card style={{border: "none"}}>
                    <CardBody className="mb-2">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>貨量</InputGroupText>
                            </InputGroupAddon>
                            <Input type="number" value={data.inventory} readOnly={true}/>
                        </InputGroup>
                    </CardBody>
                    {data.imageList.map((image) => (
                    <CardImg bottom width="100%"
                             key={`good-${data.goodID}-${image.ImageName}`}
                             src={`data:${getImageType(image.ImageBase64Content).mime};base64,${image.ImageBase64Content}`}
                             alt={`good-${data.goodID}-${image.ImageName}`} />
                    ))}
                </Card>
            </ModalBody>
            <ModalFooter className="justify-content-between">
                <Button color="secondary"
                        disabled={!canShare}
                        style={{
                            backgroundColor: canShare ? "#25B366" : "lightgrey",
                            borderColor: canShare ? "#25B366" : "lightgrey",
                            color: "white"
                        }}
                        onClick={async () => {
                            const image = data.imageList[0];
                            const imageUrl = `data:${getImageType(image.ImageBase64Content).mime};base64,${image.ImageBase64Content}`;
                            const blob = await (await fetch(imageUrl)).blob();
                            const file = new File([blob], `${data.goodDescription}.png`, { type: blob.type });
                            try {
                                await navigator.share({
                                    files: [file],
                                });
                            } catch (err) {
                                if (!navigator.canShare) {
                                    Sentry.captureMessage(`Your system doesn't support sharing files.`);
                                } else if (!navigator.canShare({files: [file]})) {
                                    Sentry.captureMessage(`Cannot share files in the provided parameters`);
                                }
                                Sentry.captureException(err);
                            }
                        }}>
                    <i className="fas fa-share-alt" /> 分享
                </Button>
                <Button color="primary" onClick={toggle}>OK</Button>
            </ModalFooter>
        </Modal>
    ) : <div/>;
}

export default GoodSearchDetailModal
