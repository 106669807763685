import React from 'react';
import {Col, Container, Input, Label, Row} from "reactstrap";

function BillRecordStatusFilter(props) {
    const {onFilterChange} = props;
    const initState = {
        notPaid: true,
        paid: true,
    };
    const [billStatusList, setBillStatusList] = React.useState(initState);

    function onItemClick(itemKey) {
        let statusList = Object.assign({}, billStatusList);
        statusList[itemKey] = !statusList[itemKey]; //  Flip selected checkbox state
        setBillStatusList(statusList);
        //  Transfer status to outer component
        onFilterChange(statusList);
    }

    return (
        <Container>
            <Row className="mt-1">
                <Col xs={6} className="p-1">
                    <div className="bg-danger p-2 text-dark text-center">
                        <Label check>
                            <Input type="checkbox"
                                   onChange={() => onItemClick('notPaid')}
                                   checked={billStatusList.notPaid}/>{' '}
                            <strong>未付</strong>
                        </Label>
                    </div>
                </Col>
                <Col xs={6} className="p-1">
                    <div className="bg-success p-2 text-dark text-center">
                        <Label check>
                            <Input type="checkbox"
                                   onChange={() => onItemClick('paid')}
                                   checked={billStatusList.paid}/>{' '}
                            <strong>已付</strong>
                        </Label>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default BillRecordStatusFilter
