import React from 'react';
import {useHistory} from "react-router-dom";
import {getAllUsers, getLastLoginList, login} from "../functions/Auth";
import AppPage from '../AppPage';
import {
    Button, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row,
} from 'reactstrap';

import logo from '../assets/logo-banner.png'
import SpinnerOverlay from "./components/SpinnerOverlay";


function LoginScreen() {
    let history = useHistory();
    const [userList, setUserList] = React.useState([]);
    const [lastLoginUserList, setLastLoginUserList] = React.useState([]);
    const [loginUsername, setLoginUsername] = React.useState([]);
    const [loginNickname, setLoginNickname] = React.useState([]);
    const [loginPassword, setLoginPassword] = React.useState([]);
    const [modal, setModal] = React.useState(false);

    React.useEffect(() => {
        const asyncFunc = async () => {
            let userList, lastLoginList;
            userList = await getAllUsers();
            setUserList(userList);
            lastLoginList = await getLastLoginList();
            setLastLoginUserList(lastLoginList);
        };
        asyncFunc().then();
    }, []);

    //  Onclick functions
    function onUsernameClick(e) {
        let userId = e.currentTarget.attributes.getNamedItem('data-id').value;
        let userItem = userList.find((item) => item.userId === userId);
        let nickName = userItem.nickName;

        setLoginUsername(userId);
        setLoginNickname(nickName);
        toggle();
    }
    async function onLoginClick(e) {
        e.preventDefault();
        if (await login(loginUsername, loginPassword)) {
            history.push(AppPage.deliveryRecord.route)
        } else {
            history.push(AppPage.login.route)
        }
    }

    const toggle = () => setModal(!modal);

    return (
        <Container className="mt-2">
            {userList.length === 0 ? <SpinnerOverlay/> : ""}
            <Row className="mt-3">
                <img className="mx-auto d-block" src={logo} style={{objectFit: "contain"}} width={200} alt="logo"/>
            </Row>
            {lastLoginUserList.length > 0 ? (
                <Row className="pl-3 pr-3 mt-3">
                    最近登入
                </Row>
            ) : ""}
            <Row className="mt-2">
                {lastLoginUserList.map((lastLoginUser, index) => {
                    return (
                        <Col xs={12} sm={12} md={6} lg={4}
                             data-id={lastLoginUser.userId} onClick={onUsernameClick} key={lastLoginUser.userId}>
                            <Button data-id={lastLoginUser.userId}
                                    color={lastLoginUser.rights}
                                    className="w-100 p-3 mt-2"
                            >
                                <h5 data-id={lastLoginUser.userId}>{lastLoginUser.nickName}</h5>
                                <h6 data-id={lastLoginUser.userId}>{lastLoginUser.userId}</h6>
                            </Button>
                        </Col>
                    )
                })}
            </Row>
            <Row className="pl-3 pr-3 mt-3">
                用戶
            </Row>
            <Row className="mt-2">
                {userList.map((user, index) => {
                    return (
                        <Col xs={12} sm={12} md={6} lg={4}
                             data-id={user.userId} onClick={onUsernameClick} key={user.userId}>
                            <Button data-id={user.userId}
                                    color={user.rights}
                                    className="w-100 p-3 mt-2"
                            >
                                <h5 data-id={user.userId}>{user.nickName}</h5>
                                <h6 data-id={user.userId}>{user.userId}</h6>
                            </Button>
                        </Col>
                    )
                })}
            </Row>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>登入 - {loginNickname}</ModalHeader>
                <ModalBody>
                    <Form method="POST" onSubmit={onLoginClick}>
                        <FormGroup>
                            <Input type="hidden" name="username" value={loginUsername}/>
                            <Label for="password">密碼</Label>
                            <Input type="password" name="password" placeholder="********"
                                   onChange={e => setLoginPassword(e.target.value)}/>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={onLoginClick}>登入</Button>{' '}
                    <Button color="secondary" onClick={toggle}>取消</Button>
                </ModalFooter>
            </Modal>
        </Container>
    );
}

export default LoginScreen
