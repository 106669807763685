import React from 'react'
import {Redirect,} from "react-router-dom";
import {getRights, isLoggedIn} from "../functions/Auth";
import {Col, Container, Label, Row, Spinner} from "reactstrap";

import NavSidebar from "./components/NavSidebar";
import SearchBar from "./components/SearchBar";
import AppPage from "../AppPage";
import SearchDateRangeBar from "./components/SearchDateRangeBar";
import SearchSortingBar from "./components/SearchSortingBar";
import SpinnerOverlay from "./components/SpinnerOverlay";
import ScreenPullToRefresh from "./components/ScreenPullToRefresh";
import {filterBillRecords, getBillRecordsFast, sortBillRecords, getAllBillRecordsWithDateRange} from "../functions/Bill";
import BillRecordCard from "./BillRecordCard";
import BillRecordStatusFilter from "./BillRecordStatusFilter";
import BillRecordDetailModal from "./BillRecordDetailModal";


function BillRecordScreen() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const oneMonthAgo = new Date(today.getTime());
    oneMonthAgo.setDate(today.getDate() - 30);
    const threeMonthAgo = new Date(today.getTime());
    threeMonthAgo.setDate(today.getDate() - 90);
    const halfYearAgo = new Date(today.getTime());
    halfYearAgo.setDate(today.getDate() - 180);

    //  Initial loading
    const [initial, setInitial] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    //  Data
    const [billData, setBillData] = React.useState([]);
    const [fastDataOnly, setFastDataOnly] = React.useState(false);  //  Not loading summary if only fast data is loaded
    const [displayData, setDisplayData] = React.useState([]);
    const unpaidBillData = displayData.filter(bill => bill.billStatus === "未付");
    const paidBillData = displayData.filter(bill => bill.billStatus === "已付");
    //  Search and filter
    const [searchString, setSearchString] = React.useState('');
    const [searchStartDate, setSearchStartDate] = React.useState(today);
    const [searchEndDate, setSearchEndDate] = React.useState(today);
    const [billStatusList, setBillStatusList] = React.useState({notPaid: true, paid: true});
    //  Sorting
    const [sortColumnName, setSortColumnName] = React.useState('paymentDateTime');
    const [sortOrder, setSortOrder] = React.useState(-1);   //  Descending
    //  Item onClick
    const [itemIDClicked, setItemIDClicked] = React.useState('');

    //  Sorting
    const columnsToSort = [
        {
            columnName: "paymentDateTime",
            name: "日期"
        },
        {
            columnName: "netPrice",
            name: "價錢"
        }
    ];
    async function onModalToggle() {
        let billData;
        setItemIDClicked('');
        setAllDataProcedure(threeMonthAgo, today);
        setIsLoading(false);
    }
    //  Pull down to refresh
    async function onPullDownRefresh() {
        setAllDataProcedure(threeMonthAgo, today);
    }

    // get the all data procedure
    async function setAllDataProcedure(startDate, endDate) {
        const billData = await getAllBillRecordsWithDateRange(startDate, endDate);
        setBillData(billData);
    }


    //  Run when state value changes
    React.useEffect(() => {
        //  Initial API call
        const initData = async () => {
            //  Load once only
            setInitial(true);
            //  Get 100 records first
            let billData;
            billData = await getBillRecordsFast();
            setBillData(billData);
            setFastDataOnly(true);
            setIsLoading(false);
            //  Full record list
            await setAllDataProcedure(threeMonthAgo, today);
            setFastDataOnly(false);
        };
        if (!initial) {
            initData().then();
        }
        //  Filter and sort
        let filteredData, sortedData;
        filteredData = filterBillRecords(billData, searchString, searchStartDate, searchEndDate, billStatusList);
        sortedData = sortBillRecords(filteredData, sortColumnName, sortOrder);
        setDisplayData(sortedData);
    }, [initial, billData, searchString, searchStartDate, searchEndDate, billStatusList, sortColumnName, sortOrder]);

    return isLoggedIn() ? (
        
        <Container className="pl-3 pr-3">
           
            {isLoading ? <SpinnerOverlay/> : ""}
            <NavSidebar/>
            <ScreenPullToRefresh onRefresh={async () => await onPullDownRefresh()}
                                 disabled={itemIDClicked !== ''}>
                <SearchBar searchValue={searchString}
                           onSearchChange={(search) => setSearchString(search)}
                           placeholder="搜尋（POS Number／客戶名／備註）"/>
                <SearchDateRangeBar onStartDateChange={(date) => setSearchStartDate(date)}
                                    onEndDateChange={(date) => setSearchEndDate(date)}
                                    defaultStartDate={searchStartDate}
                                    defaultEndDate={searchEndDate}
                                    minDateForPicker={threeMonthAgo} />
                <SearchSortingBar columnsToSort={columnsToSort}
                                  defaultColumn={columnsToSort[0]}
                                  onSortChange={(columnName, order) => {
                                      setSortColumnName(columnName);
                                      setSortOrder(order);
                                  }} />
                <BillRecordStatusFilter onFilterChange={(statusList) => setBillStatusList(statusList)}/>
                <Row className="bg-secondary mt-1 p-3">
                    <Col xs={4}>
                        <strong className={"bg-primary p-1 text-light"}>總數</strong>{' '}
                        {fastDataOnly ? <Spinner size="sm" color="primary" /> : displayData.length}
                    </Col>
                    <Col xs={4}>
                        <strong className={"bg-info p-1 text-dark"}>未付</strong>{' '}
                        {fastDataOnly ? <Spinner size="sm" color="info" /> : unpaidBillData.length}
                    </Col>
                    <Col xs={4}>
                        <strong className={"bg-success p-1 text-dark"}>已付</strong>{' '}
                        {fastDataOnly ? <Spinner size="sm" color="success" /> : paidBillData.length}
                    </Col>
                    {getRights() === 'admin' ? (
                        <>
                            <Col xs={4} className="mt-2">
                                <strong className={"bg-warning p-1 text-dark"}>金額</strong>{' '}
                                $ {fastDataOnly ? <Spinner size="sm" color="warning" /> : displayData.reduce(((prev, current) => prev + current.netPrice), 0)}
                            </Col>
                            <Col xs={4} className="mt-2">
                                <strong className={"bg-warning p-1 text-dark border border-info"}>未付</strong>{' '}
                                $ {fastDataOnly ? <Spinner size="sm" color="warning" /> : unpaidBillData.reduce(((prev, current) => prev + current.netPrice), 0)}
                            </Col>
                            <Col xs={4} className="mt-2">
                                <strong className={"bg-warning p-1 text-dark border border-success"}>已付</strong>{' '}
                                $ {fastDataOnly ? <Spinner size="sm" color="warning" /> : paidBillData.reduce(((prev, current) => prev + current.netPrice), 0)}
                            </Col>
                        </>
                    ) : ""}
                </Row>
                <Row>
                    {displayData.map((bill, index) => (
                        <Col xs={12} sm={12} md={6} lg={4}
                             data-id={bill.paymentID}
                             key={bill.paymentID}
                             onClick={() => setItemIDClicked(bill.paymentID)}>
                            <BillRecordCard data={bill}/>
                        </Col>
                    ))}
                </Row>
            </ScreenPullToRefresh>
            <BillRecordDetailModal isOpen={itemIDClicked !== ''}
                                   toggle={onModalToggle}
                                   data={billData.find(bill => bill.paymentID === itemIDClicked)}/>
        </Container>
    ) : (
        <Redirect to={AppPage.login.route} />
    )
}

export default BillRecordScreen
