import api from "./Api";
import {htmlToText} from "html-to-text";

export const searchArticle = (searchString, articleData) => {
    let articleList = [];
    if (searchString === "") return articleList;    //  Search not yet started
    articleData.forEach((category) => {
        articleList = articleList.concat(category.articles);
    });
    return articleList.filter((article) => {
        let plainTextArticle = (article.title + htmlToText(article.content)).toLowerCase();
        return plainTextArticle.includes(searchString.toLowerCase());
    });
};

export const findArticle = (articleID, articleData) => {
    let result = {};
    articleData.forEach((category) => {
        for (let i = 0; i < category.articles.length; i++) {
            let article = category.articles[i];
            if (article.articleID === articleID) {
                result = article;
            }
        }
    });
    return result;
};
export const findArticleByName = (categoryName, articleTitle, articleData) => {
    let result = {};
    articleData.forEach((category) => {
        if (category.name !== categoryName) {
            return;
        }
        for (let i = 0; i < category.articles.length; i++) {
            let article = category.articles[i];
            if (article.title === articleTitle) {
                result = article;
                return;
            }
        }
    });
    return result;
};

export const checkArticleBelongTo = (category, recentArticleID) => {
    for (let i = 0; i < category.articles.length; i++) {
        let article = category.articles[i];
        if (article.articleID === recentArticleID) {
            return true;
        }
    }
    return false;
};

export const getAllArticles = async () => {
    const response = await api.get('/wiki/articles');
    return response.data;
};

export const createCategory = async (name, priority=0) => {
    const response = await api.post(`/wiki/categories`, {
        name: name,
        priority: priority,
    });
    return response.data;
};

export const reorderCategories = async (reorderedCategories) => {
    const body = reorderedCategories.map((category, index) => ({
        categoryID: category.categoryID,
        priority: reorderedCategories.length - index
    }));
    const response = await api.put(`/wiki/categories`, body);
    return response.data;
};

export const createArticle = async (categoryID, title, priority=0) => {
    const response = await api.post(`/wiki/articles`, {
        categoryID: categoryID,
        title: title,
        content: "",
        priority: priority,
    });
    return response.data;
};

export const reorderArticles = async (reorderedArticles) => {
    const body = reorderedArticles.map((article, index) => ({
        articleID: article.articleID,
        priority: reorderedArticles.length - index
    }));
    const response = await api.put(`/wiki/articles`, body);
    return response.data;
};

export const updateArticle = async (articleData) => {
    const response = await api.put(`/wiki/articles`, [articleData]);
    return response.data;
};

export const deleteArticle = async (articleID) => {
    const response = await api.delete(`/wiki/articles/${articleID}`);
    return response.data;
};

export const deleteCategory = async (categoryID) => {
    const response = await api.delete(`/wiki/categories/${categoryID}`);
    return response.data;
};