import React from 'react'
import {Redirect} from "react-router-dom";
import {getRights, isLoggedIn} from "../functions/Auth";
import {Button, Col, Container, Row} from "reactstrap";

import NavSidebar from "./components/NavSidebar";
import SearchBar from "./components/SearchBar";
import AppPage from "../AppPage";
import {
    filterCarMaintenance, sortCarMaintenance,
    getAllCarMaintenanceRecords, getAllCarMaintenanceTypes, getAllCars,
    getCarMaintenanceRecordDetail, getCarMaintenanceRecordsFast,
} from "../functions/Car";
import CarMaintenanceCard from "./CarMaintenanceCard";
import CarMaintenanceDetailModal from "./CarMaintenanceDetailModal";
import SearchDateRangeBar from "./components/SearchDateRangeBar";
import SearchSortingBar from "./components/SearchSortingBar";
import SpinnerOverlay from "./components/SpinnerOverlay";
import {isEmpty} from "../functions/Utility";
import ScreenPullToRefresh from "./components/ScreenPullToRefresh";
import CarMaintenanceNewRecordModal from "./CarMaintenanceNewRecordModal";


function CarMaintenanceScreen() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const sixMonthAgo = new Date(today.getTime());
    sixMonthAgo.setMonth(today.getMonth() - 6);
    const isDriver = (getRights() === "driver");

    //  Initial loading
    const [initial, setInitial] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    //  Data
    const [carMaintenanceData, setCarMaintenanceData] = React.useState([]);
    const [carMaintenanceDataDetail, setCarMaintenanceDataDetail] = React.useState({});
    const [displayData, setDisplayData] = React.useState([]);
    //  Search and filter
    const [searchString, setSearchString] = React.useState('');
    const [searchStartDate, setSearchStartDate] = React.useState(sixMonthAgo);
    const [searchEndDate, setSearchEndDate] = React.useState(today);
    //  Sorting
    const [sortColumnName, setSortColumnName] = React.useState('mainDate');
    const [sortOrder, setSortOrder] = React.useState(-1);   //  Descending

    //  Sorting
    const columnsToSort = [
        {
            columnName: "mainDate",
            name: "日期"
        },
        {
            columnName: "mainFee",
            name: "價錢"
        }
    ];
    //  Item click and modal exit toggle
    async function onItemClick(carMainID) {
        let carMainDetail;
        setIsLoading(true);
        carMainDetail = await getCarMaintenanceRecordDetail(carMainID);
        setCarMaintenanceDataDetail(carMainDetail);
        setIsLoading(false);
    }
    async function onModalToggle() {
        let carMainData;
        setCarMaintenanceDataDetail({});
        setNewDataDetail({});
        carMainData = await getAllCarMaintenanceRecords();
        setCarMaintenanceData(carMainData);
        setIsLoading(false);
    }
    //  Pull down to refresh
    async function onPullDownRefresh() {
        let carMainData = await getAllCarMaintenanceRecords();
        await getAllCars();
        await getAllCarMaintenanceTypes();
        setCarMaintenanceData(carMainData);
    }

    //  Create new record
    const [newDataDetail, setNewDataDetail] = React.useState({});
    function onCreateNewRecord() {
        let newRecord = {
            carID: "",
            mainDate: "",
            mainType: "車房維修",
            mainDetail: "",
            mainFee: 0,
            mainStatus: "排期",
        };
        setNewDataDetail(newRecord);
    }

    //  Run when state value changes
    React.useEffect(() => {
        //  Initial API call
        const initData = async () => {
            //  Load once only
            setInitial(true);
            //  Get 100 records first
            let carMainData;
            carMainData = await getCarMaintenanceRecordsFast();
            setCarMaintenanceData(carMainData);
            await getAllCars();
            await getAllCarMaintenanceTypes();
            setIsLoading(false);
            //  Full record list
            carMainData = await getAllCarMaintenanceRecords();
            setCarMaintenanceData(carMainData);
        };
        if (!initial) {
            initData().then();
        }
        //  Filter and sort
        let filteredData, sortedData;
        filteredData = filterCarMaintenance(carMaintenanceData, searchString, searchStartDate, searchEndDate);
        sortedData = sortCarMaintenance(filteredData, sortColumnName, sortOrder);
        setDisplayData(sortedData);
    }, [initial, carMaintenanceData, searchString, searchStartDate, searchEndDate, sortColumnName, sortOrder]);

    return isLoggedIn() ? (
        <Container className="pl-3 pr-3">
            {isLoading ? <SpinnerOverlay/> : ""}
            <NavSidebar/>
            <ScreenPullToRefresh onRefresh={async () => await onPullDownRefresh()}
                                 disabled={!isEmpty(carMaintenanceDataDetail) || !isEmpty(newDataDetail)}>
                <SearchBar searchValue={searchString}
                           onSearchChange={(search) => setSearchString(search)}
                           placeholder={"搜尋（標題／車牌／種類／金額）"}/>
                <SearchDateRangeBar onStartDateChange={(date) => setSearchStartDate(date)}
                                    onEndDateChange={(date) => setSearchEndDate(date)}
                                    defaultStartDate={searchStartDate}
                                    defaultEndDate={searchEndDate} />
                <SearchSortingBar columnsToSort={columnsToSort}
                                  defaultColumn={columnsToSort[0]}
                                  onSortChange={(columnName, order) => {
                                      setSortColumnName(columnName);
                                      setSortOrder(order);
                                  }} />
                {!isDriver ? <Row className="mt-2">
                    <Col xs={4}>
                        <Button color="success" onClick={onCreateNewRecord}>
                            <i className="fas fa-plus"/> 新增
                        </Button>
                    </Col>
                </Row> : ""}
                <Row>
                    {displayData.map((carMaintenance, index) => (
                        <Col xs={12} sm={12} md={6} lg={4}
                             data-id={carMaintenance.mainID}
                             key={carMaintenance.mainID}
                             onClick={async () => {
                                 if (!isDriver) {
                                     await onItemClick(carMaintenance.mainID);
                                 }
                             }}>
                            <CarMaintenanceCard data={carMaintenance}/>
                        </Col>
                    ))}
                </Row>
            </ScreenPullToRefresh>
            <CarMaintenanceDetailModal isOpen={!isEmpty(carMaintenanceDataDetail)}
                                       toggle={onModalToggle}
                                       data={carMaintenanceDataDetail}/>
            <CarMaintenanceNewRecordModal isOpen={!isEmpty(newDataDetail)}
                                          toggle={onModalToggle}
                                          data={newDataDetail}/>
        </Container>
    ) : (
        <Redirect to={AppPage.login.route}/>
    )
}

export default CarMaintenanceScreen
