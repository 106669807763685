import React from 'react';
import * as ReactDOM from 'react-dom';
//import createRoot from 'react-dom/client';
import App from './App';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

Sentry.init({
    dsn: "https://2d1e59218c6641d4a6bbef5154968a78@o637252.ingest.sentry.io/5756131",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENV,
    release: process.env.REACT_APP_GIT_COMMIT_HASH,
});


const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);


root.render(
    <React.Fragment>
        <App />
    </React.Fragment>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({ scope: '/app/' });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
