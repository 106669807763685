import React, { useRef } from 'react';
import DatePicker from "react-datepicker";
import Typography from '@material-ui/core/Typography';
import {
    Button, ButtonGroup, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
    Input, InputGroup, InputGroupAddon, InputGroupText, Modal, ModalBody, ModalFooter, ModalHeader, Row,
} from "reactstrap";
import SpinnerOverlay from "./components/SpinnerOverlay";
import { isEmpty, sleep } from "../functions/Utility";
import {
    deleteCarMaintenance,
    getAllCarMaintenanceTypesFromCache,
    getAllCarsFromCache,
    getCarMaintenanceStatusColor,
    sortCarsByCarID,
    updateCarMaintenance
} from "../functions/Car";
import CarMaintenanceDetailReceiptRow from "./CarMaintenanceDetailReceiptRow";
import { isMobile } from "react-device-detect";


function CarMaintenanceDetailModal(props) {
    const { isOpen, toggle, data } = props;
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    //  Screen loading
    const [isLoading, setIsLoading] = React.useState(false);
    //  Main detail edit
    const [mainDetail, setMainDetail] = React.useState('');
    const [isMainDetailEditing, setIsMainDetailEditing] = React.useState(false);
    const onMainDetailChange = (e) => {
        setMainDetail(e.target.value);
    };
    const onMainDetailSave = async () => {
        setIsLoading(true);
        await updateCarMaintenance(data.mainID, { mainDetail: mainDetail });
        await sleep(500);
        setIsLoading(false);
        setIsMainDetailEditing(false);
    };
    //  Dropdown toggles
    const [carDropdownOpen, setCarDropdownOpen] = React.useState(false);
    const [categoryDropdownOpen, setCategoryDropdownOpen] = React.useState(false);
    //  General data update function
    const onDataUpdate = async (updateData) => {
        setIsLoading(true);
        await updateCarMaintenance(data.mainID, updateData);
        await sleep(500);
        Object.assign(data, updateData);
        setIsLoading(false);    //  Render update the loading state and changed car main status
    };
    //  Date change
    const datePickerRef = useRef(null);
    const [mainDate, setMainDate] = React.useState(null);   //  State of date picker
    const onDateChange = async (date) => {

        setIsLoading(true);
        await updateCarMaintenance(data.mainID, { mainDate: date });
        await sleep(500);
        setMainDate(date);
        setIsLoading(false);
    };
    //  Maintenance Fee
    const mainFeeRef = useRef(null);
    const [mainFee, setMainFee] = React.useState(null);   //  State of input
    const [mainFeeIsError, setMainFeeIsError] = React.useState(false);   //  State of input
    //  Delete record
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const onDeleteRecord = async () => {
        setIsLoading(true);
        await deleteCarMaintenance(data.mainID);
        await sleep(500);
        setIsDeleteModalOpen(false);
        setIsLoading(false);
        toggle()
    };

    //  Initial API call and display
    React.useEffect(() => {
        if (data) {
            // if (isEmpty(data)) return;
            setMainDetail(data.mainDetail);
            setIsMainDetailEditing(false);
            if (data.mainDate && !isNaN(new Date(data.mainDate))) {
                setMainDate(new Date(data.mainDate));
            }
            setMainFee(data.mainFee);
        }
    }, [data]);


    //  Initial API call and display
    React.useEffect(() => {
        if (isMobile && datePickerRef.current !== null) {
            datePickerRef.current.input.readOnly = true;
        }
    }, [datePickerRef]);

    return !isEmpty(data) ? (
        <Modal isOpen={isOpen} toggle={toggle}>
            {isLoading ? <SpinnerOverlay /> : ""}
            <ModalHeader toggle={toggle}>
                <div className="pt-2 pb-2">
                    {isMainDetailEditing ? (
                        <InputGroup>
                            <Input type="textarea" value={mainDetail} onChange={onMainDetailChange} />
                            <InputGroupAddon addonType="append">
                                <Button color="success" onClick={onMainDetailSave}>
                                    <i className="fas fa-check" />
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                    ) : (
                        <>
                            <span>{mainDetail}</span>
                            <Button color="link" onClick={() => setIsMainDetailEditing(true)}>
                                <i className="fas fa-pen text-dark" />
                            </Button>
                        </>
                    )}
                </div>
                <Typography color="textSecondary">
                    <strong className="bg-warning p-1 text-dark">{data.carID}</strong>{' '}
                    <strong className={"bg-" + getCarMaintenanceStatusColor(data.mainStatus) + " p-1 text-dark"}>{data.mainStatus}</strong>{' '}
                    {data.mainType}
                </Typography>
            </ModalHeader>
            <ModalBody>
                <Container>
                    <Row className="mb-2">
                        <Dropdown className="mr-2 d-inline" isOpen={carDropdownOpen}
                            toggle={() => setCarDropdownOpen(prevState => !prevState)}>
                            <DropdownToggle caret color="warning">
                                更改車牌
                            </DropdownToggle>
                            <DropdownMenu>
                                {sortCarsByCarID(getAllCarsFromCache()).map((car) => (
                                    <DropdownItem key={`${data.mainID}-dropdown-car-${car.carID}`}
                                        onClick={() => onDataUpdate({ carID: car.carID })}>{car.carID}</DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        <ButtonGroup className="mr-2">
                            <Button color={data.mainStatus === '排期' ? "info" : "secondary"}
                                style={data.mainStatus !== '排期' ? { opacity: 0.5 } : {}}
                                outline={data.mainStatus !== '排期'}
                                onClick={() => onDataUpdate({ mainStatus: '排期' })}>
                                排期
                            </Button>
                            <Button color={data.mainStatus === '完成' ? "success" : "secondary"}
                                style={data.mainStatus !== '完成' ? { opacity: 0.5 } : {}}
                                outline={data.mainStatus !== '完成'}
                                onClick={() => onDataUpdate({ mainStatus: '完成' })}>
                                完成
                            </Button>
                        </ButtonGroup>
                        <Dropdown className="mr-2 d-inline" isOpen={categoryDropdownOpen}
                            toggle={() => setCategoryDropdownOpen(prevState => !prevState)}>
                            <DropdownToggle caret color="secondary">
                                更改類別
                            </DropdownToggle>
                            <DropdownMenu>
                                {getAllCarMaintenanceTypesFromCache().map((carMainType, index) => (
                                    <DropdownItem key={`${data.mainID}-dropdown-car-main-type-${carMainType}`}
                                        onClick={() => onDataUpdate({ mainType: carMainType })}>{carMainType}</DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </Row>
                    <Row className="mb-2">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>維修日期</InputGroupText>
                            </InputGroupAddon>
                            <DatePicker
                                ref={datePickerRef}
                                selected={mainDate}
                                onChange={onDateChange}
                                dateFormat="yyyy/MM/dd"
                            />



                        </InputGroup>
                    </Row>
                    <Row className="mb-2">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>維修費用</InputGroupText>
                            </InputGroupAddon>
                            <Input ref={mainFeeRef}
                                style={mainFeeIsError ? { color: "#c13637", borderColor: "#c13637" } : {}}
                                type="text"
                                inputMode="decimal"
                                value={mainFee}
                                onChange={(e) => {
                                    if (e.target.value === "" || isNaN(e.target.value)) {
                                        setMainFeeIsError(true);
                                    } else {
                                        setMainFeeIsError(false);
                                    }
                                    setMainFee(e.target.value);
                                }} />
                            {(mainFeeRef.current !== null && data.mainFee != mainFee && mainFeeIsError === false) ?
                                <InputGroupAddon addonType="append">
                                    <Button color="success" onClick={async () => {
                                        await onDataUpdate({ mainFee: mainFee })
                                    }}>
                                        <i className="fas fa-check" />
                                    </Button>
                                </InputGroupAddon> : ""
                            }
                        </InputGroup>
                    </Row>
                    <CarMaintenanceDetailReceiptRow data={data}
                        initReceiptImageBase64={data.mainReceiptImageBase64Content}
                        onChangeStart={async (imageBase64) => {
                            setIsLoading(true);
                            await updateCarMaintenance(data.mainID, {
                                mainReceiptImageBase64Content: imageBase64
                            });
                            await sleep(500);
                        }}
                        onChangeEnd={async () => setIsLoading(false)} />
                </Container>
            </ModalBody>
            <ModalFooter className="justify-content-between">
                <Button color="danger" onClick={() => setIsDeleteModalOpen(true)}><i className="fas fa-trash" /> 刪除</Button>
                <Button color="primary" onClick={toggle}>OK</Button>
            </ModalFooter>
            <Modal isOpen={isDeleteModalOpen} toggle={() => setIsDeleteModalOpen(false)}>
                <ModalHeader toggle={() => setIsDeleteModalOpen(false)}>確認刪除？此操作無法復原！</ModalHeader>
                <ModalFooter className="justify-content-between">
                    <Button color="danger" onClick={onDeleteRecord}>刪除</Button>{' '}
                    <Button color="secondary" onClick={() => setIsDeleteModalOpen(false)}>取消</Button>
                </ModalFooter>
            </Modal>
        </Modal>
    ) : <div />;
}

export default CarMaintenanceDetailModal
