import React from 'react'
import {Button, Input, InputGroup, InputGroupAddon} from "reactstrap";
import {arrayMove, SortableContainer, SortableElement} from 'react-sortable-hoc';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js';
// import 'froala-editor/js/languages/zh_tw.js';
import './components/froala-editor/zh_tw.js'; //  Patch
import '../App.css'
import {createCategory, reorderCategories} from "../functions/Wiki";
import SpinnerOverlay from "./components/SpinnerOverlay";
import WikiCategory from "./WikiCategory";


function WikiCategoryMenu(props) {
    const {
        articleData,
        recentArticle,
        onArticleChange,
        onArticleCreated,
        onArticleReordered,
        onCategoryCreated,
        onCategoryDeleted,
        onCategoryReordered
    } = props;

    //  Initial loading
    const [isLoading, setIsLoading] = React.useState(false);
    //  Create new category
    const [isCreatingCategory, setIsCreatingCategory] = React.useState(false);
    const [newCategory, setNewCategory] = React.useState("");
    //  Delete category
    const [isDeletingCategory, setIsDeletingCategory] = React.useState(false);

    //  Create new category
    const onNewCategoryChange = (e) => {
        setNewCategory(e.target.value);
    };
    const onNewCategorySave = async () => {
        setIsLoading(true);
        await createCategory(newCategory);
        await onCategoryCreated();
        setIsLoading(false);
        setNewCategory(""); //  Reset the new category empty
        setIsCreatingCategory(false);
    };

    //  Dragging Wiki Category List
    const SortableWikiCategory = SortableElement(({value}) => (
        <WikiCategory data={value}
                      key={`wiki-category-${value.categoryID}`}
                      isDeleting={isDeletingCategory}
                      recentArticleID={recentArticle ? recentArticle.articleID : 0}
                      onArticleChange={onArticleChange}
                      onArticleCreated={onArticleCreated}
                      onCategoryDeleted={onCategoryDeleted}
                      onArticleReordered={onArticleReordered}/>
    ));
    const WikiCategorySortableList = SortableContainer(({items}) => {
        return (
            <ul className={"pl-2"}>
                {items.map((value, index) => (
                    <SortableWikiCategory key={`wiki-sortable-category-${index}`} index={index} value={value} />
                ))}
            </ul>
        );
    });
    const onCategoryReorder = async ({oldIndex, newIndex}) => {
        let reorderedData = arrayMove(articleData, oldIndex, newIndex);
        onCategoryReordered(reorderedData);
        //  Update priority to server
        setIsLoading(true);
        await reorderCategories(reorderedData);
        setIsLoading(false);
    };

    return (
        <>
            {isLoading ? <SpinnerOverlay /> : ""}
            <div className="justify-content-between p-1 mb-3 d-flex">
                <Button color=""
                        className={"text-info p-0"}
                        onClick={() => setIsCreatingCategory(!isCreatingCategory)}>
                    {!isCreatingCategory ?
                        <>
                            <i className="fas fa-plus"/> 新增分類
                        </> :
                        <>
                            <i className="fas fa-times"/> 取消
                        </>
                    }
                </Button>
                {isCreatingCategory ?
                    <InputGroup>
                        <Input type="text" value={newCategory} onChange={onNewCategoryChange}/>
                        <InputGroupAddon addonType="append">
                            <Button color="success" onClick={onNewCategorySave}>
                                <i className="fas fa-check"/>
                            </Button>
                        </InputGroupAddon>
                    </InputGroup> : ""
                }
                <Button color=""
                        className={"text-danger p-0"}
                        onClick={() => setIsDeletingCategory(!isDeletingCategory)}>
                    {!isDeletingCategory ?
                        <>
                            <i className="fas fa-trash"/> 刪除
                        </> :
                        <>
                            <i className="fas fa-times"/> 取消
                        </>
                    }
                </Button>
            </div>
            <WikiCategorySortableList items={articleData}
                                      onSortEnd={onCategoryReorder}
                                      pressDelay={200}/>
        </>
    )
}

export default WikiCategoryMenu
