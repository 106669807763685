import React from 'react'
import {Redirect} from "react-router-dom";
import {Col, Container, Row} from "reactstrap";
import {sort} from "fast-sort";

import NavSidebar from "./components/NavSidebar";
import {isLoggedIn} from "../functions/Auth";
import AppPage from "../AppPage";
import {filterGoods, getAllGoodsRecords, getGoodsRecord} from "../functions/Goods";
import GoodSearchCard from "./GoodSearchCard";
import GoodSearchDetailModal from "./GoodSearchDetailModal";
import SearchBar from "./components/SearchBar";
import SpinnerOverlay from "./components/SpinnerOverlay";
import ScreenPullToRefresh from "./components/ScreenPullToRefresh";
import {isEmpty} from "../functions/Utility";


function GoodSearchScreen() {
    //  Initial loading
    const [initialized, setInitialized] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    //  Data
    const [goodsData, setGoodsData] = React.useState([]);
    const [displayData, setDisplayData] = React.useState([]);
    const [goodsDataDetail, setGoodsDataDetail] = React.useState({});
    //  Search and filter
    const [searchString, setSearchString] = React.useState('');

    //  Item click and modal exit toggle
    async function onItemClick(goodID) {
        let goodsDetail;
        setIsLoading(true);
        goodsDetail = await getGoodsRecord(goodID);
        setGoodsDataDetail(goodsDetail);
        setIsLoading(false);
    }
    async function onModalToggle() {
        setGoodsDataDetail({});
    }
    //  Pull down to refresh
    async function onPullDownRefresh() {
        let goodsData;
        goodsData = await getAllGoodsRecords();
        setGoodsData(goodsData);
    }

    //  Run when state value changes
    React.useEffect(() => {
        //  Initial API call
        const initData = async () => {
            let goodsData;
            goodsData = await getAllGoodsRecords();
            setIsLoading(false);
            setGoodsData(goodsData);
        };
        if (!initialized) {
            initData().then(() => setInitialized(true));
        }
        //  Filter and sort
        let filteredData;
        filteredData = filterGoods(goodsData, searchString);
        setDisplayData(filteredData);
    }, [initialized, searchString, goodsData]);

    return isLoggedIn() ? (
        <Container className="pl-3 pr-3">
            {isLoading ? <SpinnerOverlay/> : ""}
            <NavSidebar/>
            <ScreenPullToRefresh onRefresh={async () => await onPullDownRefresh()}
                                 disabled={!isEmpty(goodsDataDetail)}>
                <SearchBar searchValue={searchString}
                           onSearchChange={(search) => setSearchString(search)}
                           placeholder="搜尋（貨名／種類／簡稱／備註）"/>
                <Row>
                    {sort(displayData).desc(u => u.goodPriority).map((good, index, array) => {
                        return (
                            <Col key={good.goodID}
                                 data-id={good.goodID}
                                 xs={12} sm={12} md={6} lg={4}>
                                <GoodSearchCard data={good}
                                                onDetailClick={() => onItemClick(good.goodID)}
                                                onUpdateStart={() => setIsLoading(true)}
                                                onUpdateEnd={() => setIsLoading(false)}/>
                            </Col>
                        );
                    })}
                </Row>
            </ScreenPullToRefresh>
            <GoodSearchDetailModal isOpen={!isEmpty(goodsDataDetail)}
                                   toggle={onModalToggle}
                                   data={goodsDataDetail}/>
        </Container>
    ) : (
        <Redirect to={AppPage.login.route}/>
    )
}

export default GoodSearchScreen
