import api from "./Api";

/**
 * API functions
 */
//  Car list
export const getAllCars = async () => {
    const response = await api.get('/cars');
    const carList = response.data;
    localStorage.setItem("carList", JSON.stringify(carList));
    return carList;
};
export const getAllCarsFromCache = () => {
    //  Get cached list from local storage
    let carList = localStorage.getItem("carList");
    if (carList !== null) {
        return JSON.parse(carList);
    }
    return [];
};
export const sortCarsByCarID = (carList) => {
    carList.sort((a, b) => {
        if (a.carID > b.carID) return 1;
        if (a.carID < b.carID) return -1;
        return 0;
    });
    return carList;
};

//  Car maintenance type list
export const getAllCarMaintenanceTypes = async () => {
    const response = await api.get('/carMaintenance/types');
    const carMainTypeList = response.data;
    localStorage.setItem("carMainTypeList", JSON.stringify(carMainTypeList));
    return response.data;
};
export const getAllCarMaintenanceTypesFromCache = () => {
    //  Get cached list from local storage
    let carMainTypeList = localStorage.getItem("carMainTypeList");
    if (carMainTypeList !== null) {
        return JSON.parse(carMainTypeList);
    }
    return [];
};

//  Car maintenance records
export const getAllCarMaintenanceRecords = async () => {
    const response = await api.get('/carMaintenance');
    return response.data;
};
export const getCarMaintenanceRecordsFast = async () => {
    const response = await api.get('/carMaintenance/fast');
    return response.data;
};
export const getCarMaintenanceRecordDetail = async (carMainID) => {
    const response = await api.get(`/carMaintenance/${carMainID}`);
    return response.data;
};
export const updateCarMaintenance = async (mainID, updateData) => {
    const response = await api.put(`/carMaintenance/${mainID}`, updateData);
    return response.data;
};
export const createCarMaintenance = async (newData) => {
    const response = await api.post(`/carMaintenance`, newData);
    return response.data;
};
export const deleteCarMaintenance = async (mainID) => {
    const response = await api.delete(`/carMaintenance/${mainID}`);
    return response.data;
};

/**
 * Frontend functions
 */
export const filterCarMaintenance = (carMainData, search, startDate, endDate) => {
    return carMainData.filter(data => {
        let searchMatched;
        searchMatched = (
            (data.carID && data.carID.toString().includes(search)) ||
            (data.mainType && data.mainType.includes(search)) ||
            (data.mainDetail && data.mainDetail.includes(search)) ||
            (data.mainFee && data.mainFee.toString().includes(search))
        );

        let dateMatched, finishDate;
        finishDate = new Date(data.mainDate).setHours(0, 0, 0, 0);
        dateMatched = finishDate >= startDate && finishDate <= endDate;

        return searchMatched && dateMatched;
    });
};
export const sortCarMaintenance = (carMainData, columnName, order) => {
    if (columnName === '') return carMainData;

    let sortedData = [...carMainData]; //  Copy data to another array
    sortedData.sort((a, b) => {
        let compare;
        if (columnName === "mainDate") {
            compare = (Date.parse(a[columnName]) > Date.parse(b[columnName])) ? 1 : -1;
        } else if (columnName === "mainFee") {
            compare = (parseFloat(a[columnName]) > parseFloat(b[columnName])) ? 1 : -1;
        }
        return compare * order; //  order: 1=ascending, -1=descending
    });
    return sortedData;
};
export const getCarMaintenanceStatusColor = (status) => {
    if (status === "完成") {
        return 'success';
    } else if (status === "排期") {
        return 'info';
    }
    return '';
};