import api from "./Api";
import {getAllUsersObjectCache} from "./Auth";



export const getAllInvoicePromoPicture = async () => {
    const response = await api.get(`/invoicePromoPicture/invoicePromoPictureAll`);
    return response.data;
};


export const getInvoicePromoPictureInUse = async () => {
    const response = await api.get(`/invoicePromoPicture/invoicePromoPictureByPicStatus/inUse`);
    return response.data;
};

export const getInvoicePromoPictureNotInUse = async () => {
    const response = await api.get(`/invoicePromoPicture/invoicePromoPictureByPicStatus/notInUse`);
    return response.data;
};

export const setInUse = async (ImageName) => {
    const response = await api.put(`/invoicePromoPicture/setInUse/${ImageName}`);
    return response.data;
};

export const setNotInUse = async (ImageName) => {
    const response = await api.put(`/invoicePromoPicture/setNotInUse/${ImageName}`);
    return response.data;
};


export const addInvoicePromoPicture = async (photoName, imageBase64) => {
    const response = await api.post(`/invoicePromoPicture/uploadInvoicePromoPicture`, {
        ImageName: photoName,
        ImageBase64Content: imageBase64,
    });
    return response.data;
};


export const deleteInvoicePromoPicture = async (imageName) => {
    const response = await api.delete(`/invoicePromoPicture/deleteInvoicePromoPicture/${imageName}`);
    return response.data;
};

