import React, {useRef, useState} from "react";

import './NavSidebar.default.css';
import './NavSidebar.css';
import {Container, InputGroup, InputGroupAddon, InputGroupText, Row, Button} from "reactstrap";
import '@fortawesome/fontawesome-free/css/all.min.css';

import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import zhTW from 'date-fns/locale/zh-TW';
import {isMobile} from "react-device-detect";
import {addDays}  from "../../functions/Utility";

import "./SearchDateRangeBar.css";


function SearchDateRangeBar(props) {
    const {defaultStartDate, defaultEndDate, minDateForPicker} = props;
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const startDatePickerRef = useRef(null);
    const endDatePickerRef = useRef(null);
    const [startDate, setStartDate] = useState(defaultStartDate || today);
    const [endDate, setEndDate] = useState(defaultEndDate || today);

    function onStartDateChange(date) {
        setStartDate(date);
        props.onStartDateChange(date);
    }

    function onEndDateChange(date) {
        setEndDate(date);
        props.onEndDateChange(date);
    }

    zhTW.options = {
        weekStartsOn: 0 //  Week starts on Sunday
    };
    registerLocale('zh-TW', zhTW);
    setDefaultLocale('zh-TW');

    React.useEffect(() => {
        if (isMobile && startDatePickerRef.current !== null) {
            startDatePickerRef.current.input.readOnly = true;
        }
        if (isMobile && endDatePickerRef.current !== null) {
            endDatePickerRef.current.input.readOnly = true;
        }
    }, [startDatePickerRef, endDatePickerRef]);

    return (
        <Container>
            <Row className="mt-2">
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>由</InputGroupText>
                    </InputGroupAddon>
                    
                    <InputGroupAddon addonType="append">
                    <DatePicker
                        ref={startDatePickerRef}
                        selected={startDate}
                        onChange={onStartDateChange}
                        dateFormat="yyyy-MM-dd EEE"
                        minDate={minDateForPicker}
                    /></InputGroupAddon>
                    <InputGroupAddon addonType="append">
                        <Button className="" outline color="secondary" onClick= { () => { 
                            onStartDateChange(addDays(startDate, -1));
                            }}><i className="fa fa-minus"/></Button>
                        <Button className="" outline color="secondary" onClick= { () => { 
                            onStartDateChange(addDays(startDate, 1));
                            }}> <i className="fa fa-plus"/></Button>
                    </InputGroupAddon>
                </InputGroup>

                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>至</InputGroupText>
                    </InputGroupAddon>
                    
                    <InputGroupAddon addonType="append">
                    <DatePicker
                        ref={endDatePickerRef}
                        selected={endDate}
                        onChange={onEndDateChange}
                        dateFormat="yyyy-MM-dd EEE"
                        minDate={minDateForPicker}
                    /></InputGroupAddon>
                   
                    
                    <InputGroupAddon addonType="append">
                        <Button className="" outline color="secondary" onClick= { () => { 
                            onEndDateChange(addDays(endDate, -1));
                            }}><i className="fa fa-minus"/></Button>
                        <Button className="" outline color="secondary" onClick= { () => { 
                            onEndDateChange(addDays(endDate, 1));
                            }}><i className="fa fa-plus"/></Button>
                    </InputGroupAddon>
                </InputGroup>
            </Row>
        </Container>
    );
}

export default SearchDateRangeBar