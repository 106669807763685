import React from "react";

import './NavSidebar.default.css';
import './NavSidebar.css';
import {Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row} from "reactstrap";
import '@fortawesome/fontawesome-free/css/all.min.css';

import "react-datepicker/dist/react-datepicker.css";


function SearchBar(props) {

    function onSearchChange(e) {
        props.onSearchChange(e.target.value);
    }

    return (
        <Container>
            <Row>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText><i className="fas fa-search"/></InputGroupText>
                    </InputGroupAddon>
                    <Input className="pl-2 pr-2"
                           placeholder={props.placeholder ?? "搜尋"}
                           value={props.searchValue}
                           onChange={onSearchChange}/>
                </InputGroup>
            </Row>
        </Container>
    );
}

export default SearchBar